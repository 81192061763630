import { CLEANUP_MODULE_DATA, COHORTS_FILTERS_SESSION_KEY, COHORTS_SORT_SESSION_KEY } from "@common/constants";
import { getDataFromSession, saveDataInSession } from "@mede/react-library/utils";

type CleanupFunction = () => void;

class SessionCleanupManager {
	private static instance: SessionCleanupManager;
	private cleanupModuleData: string[] = [];

	private constructor() {
		const savedCleanupData = getDataFromSession(CLEANUP_MODULE_DATA);
		if (savedCleanupData) {
			this.cleanupModuleData = savedCleanupData as string[];
		}
	}

	static getInstance() {
		if (!this.instance) {
			this.instance = new SessionCleanupManager();
		}

		return this.instance;
	}

	registerModuleCleanup(moduleName: string) {
		if (!this.cleanupModuleData.includes(moduleName)) {
			this.cleanupModuleData.push(moduleName);
			saveDataInSession(CLEANUP_MODULE_DATA, this.cleanupModuleData);
		}
	}

	runAndClear(moduleName: string) {
		if (this.cleanupModuleData.includes(moduleName)) {
			moduleCleanupFns[moduleName]();
			this.cleanupModuleData = this.cleanupModuleData.filter(m => m !== moduleName);
			saveDataInSession(CLEANUP_MODULE_DATA, this.cleanupModuleData);
		}
	}

	getRegisteredModules() {
		return this.cleanupModuleData;
	}
}

const moduleCleanupFns: Record<string, CleanupFunction> = {
	cohorts: () => {
		saveDataInSession(COHORTS_FILTERS_SESSION_KEY, null);
		saveDataInSession(COHORTS_SORT_SESSION_KEY, null);
	}
};

export const sessionCleanupManager = SessionCleanupManager.getInstance();
