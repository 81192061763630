export interface IAuthData {
	".expires": string;
	refresh_token: string;
	sessionId: number;
	host: string;
	access_token: string;
	orgUnit: string;
	userId?: number;
}

export class AuthData {
	public expires: string;
	public refresh_token: string;
	public sessionId: number;
	public host: string;
	public access_token: string;
	public orgUnit: string;
	public userId?: number;

	constructor(data: IAuthData) {
		this.expires = data[".expires"];
		this.refresh_token = data.refresh_token;
		this.sessionId = data.sessionId;
		this.host = data.host;
		this.access_token = data.access_token;
		this.orgUnit = data.orgUnit;
		this.userId = data.userId;
	}

	public isExpired(): boolean {
		return new Date(this.expires).getTime() < Date.now();
	}

	public isRefreshTokenEnabled(): boolean {
		return this.refresh_token != null;
	}
}
