import { AppDispatch } from "@redux/store";
import { NavigateFunction } from "react-router-dom";
import { setPage } from "@components/Menu/menuSlice";
import { IMenuNavigationEvent, ReportOpenedLocationType } from "@components/Menu/MenuModel";
import { menuEventTracerService } from "@components/Menu/menuEventTracerService";
import { parseLeftMenuItem } from "@components/Menu/MenuItemHandler";
import { ILeftMenuItemData } from "./LeftMenuBuilder";
import { ILeftMenuItem } from "@mede/react-library/components";
import { isNotEmptyArray } from "@mede/react-library/utils";

export function performLeftMenuNavigation(
	params: ILeftMenuItem<ILeftMenuItemData>,
	dispatch: AppDispatch,
	navigate: NavigateFunction
): void {
	saveNavigationEvent(params);
	const page = parseLeftMenuItem(params);
	const caption = (params.bucketCaption ?? params.caption)!;
	dispatch(setPage(page, params.path ?? [caption], caption, navigate, params.productId));
}

function saveNavigationEvent(menuItem: ILeftMenuItem<ILeftMenuItemData>): void {
	const event = getNavigationEvent(menuItem);
	if (event == null) {
		return;
	}

	menuEventTracerService.saveNavigationEventToStorage(event);
}

function getNavigationEvent(menuItem: ILeftMenuItem<ILeftMenuItemData>): IMenuNavigationEvent | null {
	if (!isNotEmptyArray(menuItem.path)) {
		return null;
	}

	return {
		url: menuItem.url,
		menuCaption: menuItem.caption,
		bucketCaption: menuItem.path[0],
		itemType: menuItem.data!.itemType,
		openedLocation: menuItem.data!.openedLocationType ?? ReportOpenedLocationType.ProductMenuItem
	} as IMenuNavigationEvent;
}
