import "./SessionExpirationDialog.scss";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { ControlGroup } from "@blueprintjs/core";
import { MedeButton, MedeFormGroup, MedeInputGroup, MedeSpinner } from "@mede/react-library/components";
import { CookieManager } from "@mede/react-library/utils";
import { useAppDispatch } from "@redux/reduxHooks";
import { LOGGED_OUT_MESSAGE } from "@common/constants";
import { logOut, setExpire } from "@components/App/appSlice";
import { loginApi } from "@components/Login/loginApi";
import { AuthenticationState, ISessionExpirationConfig } from "./SessionExpirationTypes";

export default function SessionExpirationDialog() {
	const [expirationModel, setExpirationModel] = useState<ISessionExpirationConfig | null>(null);

	useEffect(() => {
		loginApi.getSessionExpirationConfig().then(data => setExpirationModel(data));
	}, []);

	if (expirationModel == null) {
		return (
			<div className="session-expired-form" data-testid="session-expired-form">
				<h3 className="title">Session Timed Out</h3>
				<MedeSpinner aria-label="Loading authentication form" intent="primary"></MedeSpinner>
			</div>
		);
	}

	return (
		<div className="session-expired-form" data-testid="session-expired-form">
			<div className="logo">
				<img src={expirationModel.clientSessionExpirationLogo} alt="Client Logo" />
			</div>
			<div className="message-area" role="alertdialog">
				<Prompt expirationModel={expirationModel} />
			</div>
		</div>
	);
}

function PromptPassword(props: Readonly<{ expirationModel: ISessionExpirationConfig }>) {
	const model = props.expirationModel;
	const dispatch = useAppDispatch();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [password, setPassword] = useState<string>("");
	const [errorMessage, setErrorMessage] = useState<string>();
	const onBeforeUnload = () => CookieManager.setCookie("logoutMessage", LOGGED_OUT_MESSAGE);

	useEffect(() => {
		addEventListener("beforeunload", onBeforeUnload);
		return () => removeEventListener("beforeunload", onBeforeUnload);
	}, []);

	const onSubmit = useCallback(
		(e: FormEvent) => {
			e.preventDefault();
			setErrorMessage(undefined);
			setIsLoading(true);
			loginApi
				.checkPassword(password)
				.then(result => {
					switch (result) {
						case AuthenticationState.AuthFailed:
							setErrorMessage(model.incorrectPasswordMessage);
							break;
						case AuthenticationState.Logout:
							dispatch(logOut());
							break;
						case AuthenticationState.UnAuthorizedIPaddress:
							setErrorMessage(model.unAuthorizedIpMessage);
							break;
						default:
							setErrorMessage(undefined);
							dispatch(setExpire(false));
							break;
					}
				})
				.finally(() => {
					setIsLoading(false);
					setPassword("");
				});
		},
		[password]
	);

	const onLogoutClick = () => {
		dispatch(logOut());
	};
	const inputId = "passInput";

	return (
		<form onSubmit={onSubmit}>
			<h1 className="title">Session Timed Out</h1>
			<span className={"description"}>
				Type password and press &quot;Continue Working&quot; button to verify your credentials and resume your session.
			</span>
			<MedeFormGroup
				labelFor={inputId}
				label={"Password"}
				required
				intent={errorMessage != null ? "danger" : "none"}
				fill
				helperText={<span>{errorMessage}</span>}
			>
				<MedeInputGroup
					id={inputId}
					type={"password"}
					disabled={isLoading}
					value={password}
					placeholder="Enter password"
					onChange={e => setPassword(e.target.value)}
					autoFocus
					autoComplete="off"
				/>
			</MedeFormGroup>
			<ControlGroup vertical fill>
				<MedeButton intent="primary" size={"l"} fill text="Continue Working" type="submit" loading={isLoading} />
				<MedeButton
					intent="primary"
					size={"l"}
					minimal
					fill
					text="Logout"
					disabled={isLoading}
					onClick={onLogoutClick}
				/>
			</ControlGroup>
		</form>
	);
}

function PromptSso(props: Readonly<{ expirationModel: ISessionExpirationConfig }>) {
	const model = props.expirationModel;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	const runSsoLogin = useCallback(() => {
		if (model.showReloginPageInPopup) {
			setIsLoading(true);
			const strWindowFeatures = "menubar=no,location=no,resizable=yes,scrollbars=no,status=yes;width=800;height=600;";
			const popupLoginWindow = window.open(model.ssoReloginUrl, "ssoReloginPopup", strWindowFeatures);

			if (popupLoginWindow) {
				popupLoginWindow.focus();
				popupLoginWindow.onclose = () => setIsLoading(false);
			}
		} else {
			window.location.replace(model.ssoReloginUrl);
		}
	}, []);

	const onLogoutClick = useCallback(() => {
		dispatch(logOut());
	}, []);

	return (
		<div className="form">
			<h1 className="title">Session Timed Out</h1>
			<span className={"description"}>
				Press &quot;Continue Working&quot; button to verify your credentials and resume your session.
			</span>
			<ControlGroup vertical fill>
				<MedeButton
					intent="primary"
					size={"l"}
					fill
					text="Continue Working"
					type="button"
					loading={isLoading}
					onClick={runSsoLogin}
				/>
				{model.showSsoLogout && (
					<MedeButton
						intent="primary"
						minimal
						size={"l"}
						fill
						text="Logout"
						disabled={isLoading}
						onClick={onLogoutClick}
					/>
				)}
			</ControlGroup>
		</div>
	);
}

function PromptIdentityServer(props: Readonly<{ expirationModel: ISessionExpirationConfig }>) {
	const model = props.expirationModel;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	const runIdentityLogin = useCallback(() => {
		setIsLoading(true);
		const strWindowFeatures = "menubar=no,location=no,resizable=yes,scrollbars=no,status=yes;width=800;height=600;";
		const popupLoginWindow = window.open(model.identityReloginUrl, "identityReloginPopup", strWindowFeatures);

		if (popupLoginWindow) {
			popupLoginWindow.focus();
			popupLoginWindow.onclose = () => setIsLoading(false);
		}
	}, []);

	const onLogoutClick = useCallback(() => {
		dispatch(logOut());
	}, []);

	return (
		<div className="form">
			<h1 className="title">Session Timed Out</h1>
			{model.showReloginPageInPopup && (
				<span className={"description"}>
					Press &quot;Continue Working&quot; button to verify your credentials and resume your session.
				</span>
			)}
			<ControlGroup vertical fill>
				{model.showReloginPageInPopup && (
					<MedeButton
						intent="primary"
						size={"l"}
						fill
						text="Continue Working"
						type="button"
						loading={isLoading}
						autoFocus={true}
						onClick={runIdentityLogin}
					/>
				)}
				<MedeButton
					intent="primary"
					minimal
					size={"l"}
					fill
					text="Logout"
					disabled={isLoading}
					onClick={onLogoutClick}
				/>
			</ControlGroup>
		</div>
	);
}

function Prompt(props: Readonly<{ expirationModel: ISessionExpirationConfig }>) {
	const model = props.expirationModel;

	if (model == null) {
		return <></>;
	}

	if (model.isLoggedInWithIdentityServer) {
		return <PromptIdentityServer expirationModel={model} />;
	}

	return model.isSsoLogin ? <PromptSso expirationModel={model} /> : <PromptPassword expirationModel={model} />;
}
