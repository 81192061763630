import "./LeftMenuWrapper.scss";
import * as LeftMenuBuilder from "./LeftMenuBuilder";
import { useAppDispatch, useAppSelector } from "@redux/reduxHooks";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { performLeftMenuNavigation } from "./LeftMenuNavigation";
import SearchProductItemsInput from "@components/Menu/TopNavigation/SearchProductItemsInput";
import { ILeftMenuItem, ILeftMenuSettings, LeftMenu } from "@mede/react-library/components";
import {
	ILeftMenuItemData,
	ISelectedFavoriteInfo,
	ISelectedMenuItemInfo,
	ISelectedWorkListInfo
} from "./LeftMenuBuilder";
import { FAVORITES_PAGE_CAPTION, WORK_LISTS_PAGE_CAPTION } from "@common/constants";
import { MenuNodeType } from "@components/Menu/MenuModel";

export default function LeftMenuWrapper(props: Readonly<{ container?: HTMLElement }>) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const menuData = useAppSelector(x => x.menu.menuData);
	const alertsCount = useAppSelector(x => x.menu.alertsCount);
	const collaborationsCount = useAppSelector(x => x.menu.collaborations.length);
	const isLoading = useAppSelector(x => x.menu.isLoading);
	const productId = useAppSelector(x => x.menu.product?.id);
	const user = useAppSelector(s => s.menu.menuData.user);
	const selectedBucketCaption = useAppSelector(s => s.menu.menuBucketCaption);
	const breadcrumb = useAppSelector(s => s.menu.breadcrumbHistory);

	const selectedItemPath = useMemo(() => {
		const result: ISelectedMenuItemInfo[] = [{ caption: selectedBucketCaption!, url: null }];
		for (const item of breadcrumb) {
			if (item.path.length > 0) {
				result.push({
					caption: item.path[0],
					url: item.uri
				});
			}
		}

		return result;
	}, [selectedBucketCaption, breadcrumb]);

	const logo = useMemo(() => LeftMenuBuilder.buildLogo(menuData), [menuData]);
	const products = useMemo(
		() => LeftMenuBuilder.buildProducts(menuData, productId, selectedItemPath),
		[menuData, productId, selectedItemPath]
	);

	const selectedFavorite = useMemo(() => {
		if (selectedBucketCaption != FAVORITES_PAGE_CAPTION || breadcrumb.length == 0) {
			return null;
		}

		return {
			isPersonal: breadcrumb[0].path[0] == "Personal Favorites",
			caption: breadcrumb[0].path[1]
		} as ISelectedFavoriteInfo;
	}, [selectedBucketCaption, breadcrumb]);

	const favorites = useMemo(
		() => LeftMenuBuilder.buildFavoritesOrWorkLists(menuData, selectedFavorite, MenuNodeType.Favorite),
		[
			menuData.settings,
			menuData.productsData.personalFavoriteItems,
			menuData.productsData.organizationalFavoriteItems,
			selectedFavorite
		]
	);

	const selectedWorkList = useMemo(() => {
		if (selectedBucketCaption != WORK_LISTS_PAGE_CAPTION || breadcrumb.length == 0) {
			return null;
		}

		return {
			isPersonal: breadcrumb[0].path[0] == "Personal Work Lists",
			caption: breadcrumb[0].path[1]
		} as ISelectedWorkListInfo;
	}, [selectedBucketCaption, breadcrumb]);

	const workLists = useMemo(
		() => LeftMenuBuilder.buildFavoritesOrWorkLists(menuData, selectedWorkList, MenuNodeType.WorkList),
		[
			menuData.settings,
			menuData.productsData.personalCustomDetailsItems,
			menuData.productsData.organizationalCustomDetailsItems,
			selectedWorkList
		]
	);

	const home = useMemo(() => LeftMenuBuilder.buildHomeButton(productId), [productId]);
	const insights = useMemo(() => LeftMenuBuilder.buildInsights(menuData), [menuData.settings]);
	const cohorts = useMemo(() => LeftMenuBuilder.buildCohorts(menuData), [menuData.settings]);
	const tools = useMemo(
		() => LeftMenuBuilder.buildTools(menuData.tools, selectedItemPath),
		[menuData.tools, selectedItemPath]
	);
	const alerts = useMemo(
		() => LeftMenuBuilder.buildAlerts(menuData.notifications, alertsCount),
		[menuData.notifications, alertsCount]
	);
	const collaborations = useMemo(
		() => LeftMenuBuilder.buildCollaborations(menuData.notifications, collaborationsCount),
		[menuData.notifications, collaborationsCount]
	);
	const userMenuItem = useMemo(
		() => LeftMenuBuilder.buildUserMenuItems(menuData.user.userItems),
		[menuData.user.userItems]
	);
	const toolsGroup = useMemo(() => {
		return [home, insights, favorites, workLists, alerts, collaborations, cohorts, tools].filter(
			(x): x is ILeftMenuItem<ILeftMenuItemData> => x != null
		);
	}, [home, favorites, alerts, collaborations, tools, workLists]);

	const navigationHandler = (item: ILeftMenuItem<ILeftMenuItemData>) =>
		performLeftMenuNavigation(item, dispatch, navigate);
	const searchControl = menuData.settings.hasMenuSearchAccess && (
		<SearchProductItemsInput
			container={props.container}
			inputPlaceholder="Search"
			className="search-control"
			portalClassName="left-menu-search-portal"
		/>
	);

	const menuSettings: ILeftMenuSettings<ILeftMenuItemData> = {
		logo,
		navigationHandler,
		search: searchControl,
		productsMenuItems: products,
		currentProduct: productId,
		toolsMenuItems: toolsGroup,
		userMenuItem: userMenuItem,
		user: {
			fullName: user.userFullName,
			email: user.userEmail
		}
	};

	return <LeftMenu settings={menuSettings} container={props.container} isLoading={isLoading} />;
}
