import { KeyboardEvent } from "react";
import { IMenuItemBaseDto, IMenuItemDto, MenuNodeType } from "@components/Menu/MenuModel";

export interface IKeyBoardNavigationSettings {
	activeItemId: string;
	closeSubItemsAction?: () => void;
	setActiveItemAction: (id: string) => void;
	setActiveChildAction: (id: string) => void;
	siblings: Array<IMenuItemDto>;
	onItemKeyDownAction?: (e: KeyboardEvent<HTMLAnchorElement>) => void;
}

export const onMenuItemKeyDown = (e: KeyboardEvent<HTMLAnchorElement>, settings: IKeyBoardNavigationSettings): void => {
	switch (e.key) {
		case "Tab":
			e.stopPropagation();
			e.preventDefault();
			return;
		case "Escape":
			settings.closeSubItemsAction?.();
			return;
	}

	if (!["ArrowDown", "ArrowUp", "ArrowRight", "ArrowLeft", "Enter"].includes(e.key)) {
		return;
	}

	let currentMenuItem: IMenuItemDto | undefined;
	if (e.key === "Enter" || e.key === "ArrowRight") {
		currentMenuItem = findCurrentMenuItem(settings.activeItemId, settings.siblings);
		if (currentMenuItem?.children == null || currentMenuItem?.children.length === 0) {
			if (e.key === "Enter" && settings.onItemKeyDownAction) {
				settings.onItemKeyDownAction(e);
			}
			return;
		}
	}

	e.stopPropagation();
	e.preventDefault();

	switch (e.key) {
		case "ArrowLeft":
			settings.closeSubItemsAction?.();
			return;

		case "ArrowRight":
		case "Enter":
			if (settings.activeItemId !== "") {
				setChildItemIdOnOpen(currentMenuItem, settings);
			}
			return;

		case "ArrowDown":
			setCurrentItemIdOnDown(settings);
			return;
		case "ArrowUp":
			setCurrentItemIdOnUp(settings);
			return;
	}
};

function setCurrentItemIdOnUp(settings: IKeyBoardNavigationSettings): void {
	const flatList = settings.siblings;
	setCurrentItem(
		flatList,
		(itemId: string, index: number, activeItemId: string): IMenuItemBaseDto | null => {
			if (activeItemId === "") {
				return flatList[getPreviousItemIndex(0, flatList)];
			} else if (itemId === activeItemId) {
				return flatList[getPreviousItemIndex(index, flatList)];
			}
			return null;
		},
		settings
	);
}

function setChildItemIdOnOpen(currentMenuItem: IMenuItemDto | undefined, settings: IKeyBoardNavigationSettings): void {
	const flatList = flatFirstLevelItems(currentMenuItem!.children!);
	setChildItemId(
		flatList,
		(): IMenuItemBaseDto | null => {
			return flatList[getNextItemIndex(-1, flatList)];
		},
		settings
	);
}

function setCurrentItemIdOnDown(settings: IKeyBoardNavigationSettings): void {
	const flatList = settings.siblings;
	setCurrentItem(
		flatList,
		(itemId: string, index: number, activeItemId: string): IMenuItemBaseDto | null => {
			if (activeItemId === "") {
				return flatList[getNextItemIndex(-1, flatList)];
			} else if (itemId === activeItemId) {
				return flatList[getNextItemIndex(index, flatList)];
			}
			return null;
		},
		settings
	);
}

function getPreviousItemIndex(currentIndex: number, items: Array<IMenuItemDto>): number {
	const index = currentIndex - 1;
	if (index < 0) {
		return getPreviousItemIndex(items.length, items);
	} else if (
		(items[index].itemType === MenuNodeType.Group || items[index].itemType === MenuNodeType.Section) &&
		(!items[index].children || items[index].children?.length === 0)
	) {
		return getPreviousItemIndex(index, items);
	}
	return index;
}

function getNextItemIndex(currentIndex: number, items: Array<IMenuItemDto>): number {
	const index = currentIndex + 1;
	if (items.length - 1 < index) {
		return getNextItemIndex(-1, items);
	} else if (
		(items[index].itemType === MenuNodeType.Group || items[index].itemType === MenuNodeType.Section) &&
		(!items[index].children || items[index].children?.length === 0)
	) {
		return getNextItemIndex(index, items);
	}
	return index;
}

function setChildItemId(
	flatList: Array<IMenuItemBaseDto>,
	getItemFunc: (itemId: string, index: number) => IMenuItemBaseDto | null,
	settings: IKeyBoardNavigationSettings
): void {
	flatList.every((item: IMenuItemBaseDto, index) => {
		if (item.id && item.id.length > 0) {
			const nextItem = getItemFunc(item.id, index);
			if (nextItem?.id?.length) {
				settings.setActiveChildAction(nextItem.id);
				return false;
			}
		}
		return true;
	});
}

function setCurrentItem(
	flatList: Array<IMenuItemBaseDto>,
	getItemFunc: (itemId: string, index: number, activeItemId: string) => IMenuItemBaseDto | null,
	settings: IKeyBoardNavigationSettings
): void {
	flatList.every((item: IMenuItemBaseDto, index) => {
		if (item.id && item.id.length > 0) {
			const nextItem = getItemFunc(item.id, index, settings.activeItemId);
			if (nextItem?.id?.length) {
				settings.setActiveItemAction(nextItem.id);
				return false;
			}
		}
		return true;
	});
}

function findCurrentMenuItem(itemId: string, menuItems: Array<IMenuItemDto>): IMenuItemDto | undefined {
	let item: IMenuItemDto | undefined;
	menuItems.some((i: IMenuItemDto) => {
		if (i.id === itemId) {
			item = i;
			return true;
		} else if (i.children && i.children.length > 0) {
			item = findCurrentMenuItem(itemId, i.children);
			return item !== undefined;
		}
		return false;
	});
	return item;
}

function getNavId(item: IMenuItemBaseDto, index: number, parentIndex?: number): string | null {
	if (item.id && item.id.length > 0) {
		return item.id;
	}

	if (item.caption?.length) {
		return [item.caption.toLowerCase().replace(/[^\w]/g, " ").replace(/\s+/g, "-"), index, parentIndex]
			.filter(i => i != null)
			.join("-");
	}
	return null;
}

export function flatFirstLevelItems(source: Array<IMenuItemDto>): Array<IMenuItemDto> {
	const flatList = new Array<IMenuItemDto>();
	if (source.length === 0) {
		return flatList;
	}
	const isItemSeparator = (items: Array<IMenuItemDto>, index: number): boolean => {
		return items[index]?.itemType === MenuNodeType.Section || items[index]?.itemType === MenuNodeType.Group;
	};

	return source.reduce((accum: IMenuItemDto[], item: IMenuItemDto, index: number) => {
		let list = [...accum];
		if (isItemSeparator(source, index) && item.children && item.children.length > 0) {
			list = [
				...list,
				{ ...item, children: null } as IMenuItemDto,
				...item.children.map((i, childIndex) => {
					return { ...i, id: getNavId(i, childIndex, index) } as IMenuItemDto;
				})
			];

			// adds an empty Section as separator
			if (
				!isItemSeparator(list, list.length - 1) &&
				source.length - 1 > index &&
				source.length >= index + 1 &&
				!isItemSeparator(source, index + 1)
			) {
				list.push({ itemType: item.itemType, children: null } as IMenuItemDto);
			}
		} else {
			const id = getNavId(item, index);
			if (id !== null) {
				list = [...list, { ...item, id: id }];
			}
		}
		return list;
	}, flatList);
}

export function isFavoriteUrl(uri: string | null): boolean {
	if (!uri) {
		return false;
	}

	const lowerUri = uri.toLowerCase();
	return (
		lowerUri.includes("favoriteid=") ||
		lowerUri.includes("fvid=") ||
		lowerUri.includes("app/board/fav/") ||
		lowerUri.includes("app/summary/fav/")
	);
}

export function isRootMenuItem(uri: string | null): boolean {
	if (!uri) {
		return false;
	}

	const lowerUri = uri.toLowerCase();
	return (
		lowerUri == "/" ||
		lowerUri.includes("app/insights") ||
		lowerUri.includes("app/cohorts") ||
		lowerUri.includes("app/alerts") ||
		lowerUri.includes("islandingpage=true")
	);
}
