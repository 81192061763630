import { FontAwesomeIconDto } from "@mede/react-library/core";

export interface IMenuModel {
	productsData: IProductsMenuData;
	user: IMenuUser;
	logo: IMenuLogoModel;
	smallLogo: IMenuLogoModel;
	largeLogo: IMenuLogoModel;
	helpMenuItems: IMenuItemDto[];
	notifications: IMenuItemDto[];
	tools: IMenuItemDto[];
	settings: IMenuSettings;
}

export interface IMenuSettings {
	hasAnyProductConfigured: boolean;
	hasMenuSearchAccess: boolean;
	hasHelpSupportMenuItemAccess: boolean;
	hasAccessToReadSharedFavorites: boolean;
	hasAccessToReadSharedCustomDetails: boolean;
	hasAccessToTopUsedMenuItems: boolean;
	hasInsightsAccess: boolean;
	hasCohortsAccess: boolean;
	hasMedeAchieveAccess: boolean;
}

export interface IFavoritesMenuData {
	personalFavoriteItems: IMenuItemDto[];
	organizationalFavoriteItems: IMenuItemDto[];
	personalCustomDetailsItems: IMenuItemDto[];
	organizationalCustomDetailsItems: IMenuItemDto[];
}

export interface IProductsMenuData extends IFavoritesMenuData {
	currentProductId: string;
	products: IProductItemDto[];
	productMenuItems: Dictionary<IProductMenuModel>;
	productMenuCaption: string;
	productDropdownCaption: string;
}

export interface IProductItemDto {
	type: ProductType;
	id: string;
	caption: string;
	icon?: FontAwesomeIconDto;
	preventSwitchProduct: boolean;
	openMenu: boolean;
	url?: string;
	target?: string;
}

export interface IProductMenuModel {
	product: string;
	menuItems: IMenuItemDto[];
}

export interface IMenuItemBaseDto {
	id: string | null;
	caption: string | null;
	testId?: string | null;
}

export interface IMenuItemDto extends IMenuItemBaseDto {
	url: string | null;
	key: string | null;
	productId: string | null;
	itemType: MenuNodeType | keyof typeof MenuNodeType;
	children: IMenuItemDto[] | null;
	isVisible: boolean;
	actionType: MenuActionType | keyof typeof MenuActionType;
	leftMenuNodeType: string;
	searchTarget: string | null;
	searchTargetDescription: string | null;
	searchTargetOpen: string | null;
	page?: string | null;
	position?: number | null;
}

export interface IMenuUser {
	userFullName: string;
	userEmail: string;
	userItems: Dictionary<IMenuItemDto[]>;
}

export interface IMenuLogoModel {
	title: string;
	imageSrc: string;
	logoHref: string;
}

export enum MenuNodeType {
	Report = "Report",
	Page = "Page",
	Scorecards = "Scorecards",
	Sheet = "Sheet",
	Calculator = "Calculator",
	Dashboard = "Dashboard",
	Detailed = "Detailed",
	FavoriteFolder = "FavoriteFolder",
	Favorite = "Favorite",
	CollaborationFolder = "CollaborationFolder",
	Collaboration = "Collaboration",
	Folder = "Folder",
	Restore = "Restore",
	Restrict = "Restrict",
	SelfPay = "SelfPay",
	SuperQuery = "SuperQuery",
	CustomDetailFolder = "CustomDetailFolder",
	CustomDetail = "CustomDetail",
	UrlNode = "UrlNode",
	Sps = "Sps",
	Admin = "Admin",
	InvalidateFavorites = "InvalidateFavorites",
	Notifications = "Notifications",
	InvalidateLayouts = "InvalidateLayouts",
	CascadingAuthentication = "CascadingAuthentication",
	Rstate = "Rstate",
	Clients = "Clients",
	ReportingServices = "ReportingServices",
	ReportViewer = "ReportViewer",
	Map = "Map",
	Download = "Download",
	MedePortal = "MedePortal",
	DocumentManagement = "DocumentManagement",
	WorkList = "WorkList",
	TemplatedExport = "TemplatedExport",
	SsoLink = "SsoLink",
	ExternalApplication = "ExternalApplication",
	JavascriptNode = "JavascriptNode",
	ModalPopup = "ModalPopup",
	Section = "Section",
	Group = "Group",
	Board = "Board",
	FavBoard = "FavBoard",
	PatientSearch = "PatientSearch",
	ReportScheduler = "ReportScheduler",
	SsrsIntegrated = "SsrsIntegrated",
	FavoriteMenu = "FavoriteMenu",
	CustomDetailMenu = "CustomDetailMenu",
	SsrsMenu = "SsrsMenu",
	ToolbarHelp = "ToolbarHelp",
	ToolbarUserPreferences = "ToolbarUserPreferences",
	ToolbarUserLayoutManagement = "ToolbarUserLayoutManagement"
}

export enum MenuActionType {
	EvalScript = "EvalScript",
	OpenInMainFrame = "OpenInMainFrame",
	OpenInPopup = "OpenInPopup",
	OpenInPopupModal = "OpenInPopupModal",
	OpenInTabAllowed = "OpenInTabAllowed",
	Redirect = "Redirect"
}

export enum LeftMenuNodeType {
	MostUsedFolder = "MostUsedFolder",
	RecentlyUsedFolder = "RecentlyUsedFolder",
	RecentlyUsedItem = "RecentlyUsedItem",
	MostUsedItem = "MostUsedItem"
}

export enum FavoriteType {
	Any = 0,
	Personal = 1,
	Shared = 2
}

export enum ReportOpenedLocationType {
	Undefined = "Undefined",
	PersonalFavorites = "PersonalFavorites",
	OrganizationalFavorites = "OrganizationalFavorites",
	ProductMenuItem = "LeftMenu",
	Charts = "Charts",
	Update = "Update",
	Reload = "Reload",
	PersonalWorkLists = "PersonalWorkLists",
	OrganizationalWorkLists = "OrganizationalWorkLists"
}

export enum MenuItemPageType {
	UserPreferences = "UserPreferences",
	Profile = "ProfilePage",
	ChangePassword = "ChangePasswordPage",
	ToolbarUserLayoutManagement = "ToolbarUserLayoutManagement",
	Logout = "Logout",
	PersonalFavorites = "PersonalFavorites",
	OrganizationalFavorites = "OrganizationalFavorites"
}

export enum ProductType {
	Default = "Default",
	ExternalApp = "ExternalApp"
}
export class UserItemType {
	public static readonly ChangePassword: string = "ChangePassword";
	public static readonly Logout: string = "Logout";
	public static readonly Profile: string = "Profile";
	public static readonly Preferences: string = "Preferences";
	public static readonly LayoutManagement: string = "LayoutManagement";
}

export interface ICommonMenuProps {
	container?: HTMLElement;
}

export interface ISwitchProductDto {
	logo: IMenuLogoModel;
	productId: string;
}

export interface IMenuNavigationEvent {
	url: string;
	bucketCaption: string;
	menuCaption: string;
	itemType: string;
	openedLocation?: ReportOpenedLocationType;
	navigationType?: string;
}
