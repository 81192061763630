import { SessionStatus } from "@components/App/SessionExpiration/SessionExpirationTypes";
import BroadcastChannelWrapper from "@common/BroadcastChannelWrapper";

class SessionBroadcastChannel {
	private static instance: SessionBroadcastChannel;

	private readonly channel: BroadcastChannelWrapper;
	private readonly handlers: Dictionary<MessageHandler[]>;

	private constructor() {
		this.handlers = {};
		this.channel = new BroadcastChannelWrapper("session_sync_channel");
		this.channel.onmessage = e => this.onMessageReceived(e.data);
	}

	public static getInstance(): SessionBroadcastChannel {
		if (!SessionBroadcastChannel.instance) {
			SessionBroadcastChannel.instance = new SessionBroadcastChannel();
		}

		return SessionBroadcastChannel.instance;
	}

	public notifyLoggedIn(csrfToken: string): void {
		this.channel.postMessage(SessionStatus.LoggedIn + "|" + csrfToken);
	}

	public notifyTimeOut(): void {
		this.channel.postMessage(SessionStatus.TimeOut);
	}

	public notifyLoggedOut(): void {
		this.channel.postMessage(SessionStatus.LoggedOut);
	}

	public notifyLoggedOutByOtherSession(): void {
		this.channel.postMessage(SessionStatus.LoggedOutByOtherSession);
	}

	public subscribeOnLoggedIn(callback: MessageHandler): void {
		this.subscribe(SessionStatus.LoggedIn, callback);
	}

	public subscribeOnTimeOut(callback: MessageHandler): void {
		this.subscribe(SessionStatus.TimeOut, callback);
	}

	public subscribeOnLoggedOut(callback: MessageHandler): void {
		this.subscribe(SessionStatus.LoggedOut, callback);
	}

	public subscribeOnLoggedOutByOtherSession(callback: MessageHandler): void {
		this.subscribe(SessionStatus.LoggedOutByOtherSession, callback);
	}

	public subscribeOnAny(callback: MessageHandler): void {
		this.subscribeOnLoggedIn(callback);
		this.subscribeOnTimeOut(callback);
		this.subscribeOnLoggedOut(callback);
		this.subscribeOnLoggedOutByOtherSession(callback);
	}

	private subscribe(type: string, callback: MessageHandler): void {
		if (this.handlers[type] == null) {
			this.handlers[type] = [];
		}

		this.handlers[type].push(callback);
	}

	private onMessageReceived(data: string): void {
		const parts = data.split("|");
		const message = parts[0];
		const token = parts[1];
		const handlerData = { event: message, token };

		const messageHandlers = this.handlers[message];
		if (messageHandlers == null) {
			return;
		}

		for (const messageHandler of messageHandlers) {
			messageHandler(handlerData);
		}
	}
}

declare type MessageHandler = (data: { event: string; token: string | undefined }) => void;
export default SessionBroadcastChannel.getInstance;
