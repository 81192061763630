import { ITimeFilterDto } from "@components/Insights/FilterPanel/InsightsFilterPanelModels";
import { IMemberFilter } from "@components/Insights/Analyst/AnalystModel";
import { IInsightDialogModel, IMetricSimple } from "@components/Insights/Panel/InsightModel";

export enum AlertType {
	Insight = "Insight",
	Report = "Report"
}

export enum AlertPriority {
	Critical = "Critical",
	Attention = "Attention",
	Info = "Info",
	Positive = "Positive"
}

export const AlertTriggerOperation: Dictionary<string> = {
	Less: "is less than",
	Greater: "is greater than",
	LessOrEqual: "is less or equal to",
	GreaterOrEqual: "is greater or equal to",
	Equal: "is equal to",
	NotEqual: "is not equal to",
	Increase: "increased by",
	Decrease: "decreased by",
	IncreasePercent: "increased by %",
	DecreasePercent: "decreased by %",
	Between: "between",
	NotBetween: "not between"
};

export interface AlertMetric {
	value: string;
	change: string | null;
	previous: string | null;
}

export interface IAlertModel {
	id: number;
	name: string;
	priority: AlertPriority;
	description: string;
	isRead: boolean;
	isTriggered: boolean;
	modifiedDate: string;
	triggeredDate?: string;
	type: AlertType;
	metric: AlertMetric;
	insightId: string | null;
	favoriteId: number;
}

export interface IAlertDialogModel extends IInsightDialogModel {
	operation?: string;
	operationArgument1?: string;
	operationArgument2?: string;
	effectiveDate?: string;
	priority?: AlertPriority;
	shouldNotify?: boolean;
	shareUsers?: string[];
	notificationMessage?: string;
	alertOperationId?: number;
}

export interface IAlertDataModel {
	insightId: string;
	alertName: string;
	metric: IMetricSimple;
	operation: string;
	operationArgument1: number;
	operationArgument2?: number;
	effectiveDate: string;
	priority: AlertPriority;
	shouldNotify: boolean;
	shareUsers: string[];
	notificationMessage?: string;
	timeFilter: ITimeFilterDto;
	filters: IAlertMemberFilter[];
}

export interface IAlertMemberFilter {
	axisId: string;
	dimensionCaption: string;
	includeMembers: IAlertMemberFilterMember[];
}

export interface IAlertMemberFilterMember {
	name: string;
	caption: string;
}

export interface ISaveAlertModel {
	insightId: string;
	alertOperationId?: number;
	favoriteId?: number;
	axisId: string;
	widgetPath?: string;
	targetMember?: string;
	alertName: string;
	metricName: string;
	operation: string;
	operationArgument1?: string;
	operationArgument2?: string;
	effectiveDate: Date;
	priority: string;
	shouldNotify: boolean;
	distributionAccounts: Array<string>;
	distributionGroups: Array<string>;
	notificationMessage: string;
}

export interface ISaveAlertRequest {
	alertOperationId?: number;
	alertName: string;
	operation: string;
	operationArgument1?: string;
	operationArgument2?: string;
	effectiveDate: Date;
	priority: string;
	shouldNotify: boolean;
	distributionAccounts: Array<string>;
	distributionGroups: Array<string>;
	notificationMessage: string;
}

export interface ICreateAlertRequest extends ISaveAlertRequest {
	favoriteId: number;
	insightId: string;
	widgetPath?: string;
	timeFilter: ITimeFilterDto;
	targetMember: IMemberFilter | null;
	memberFilters: IMemberFilter[];
	metricName: string;
}

export interface IAlertInsights {
	name: string;
	title: string;
	description: string;
}
