import "./Dialogs.scss";
import { useAppSelector } from "@redux/reduxHooks";
import MedeLegacyDialog from "@components/Dialog/MedeLegacyDialog";
import { DialogEntity } from "@components/Dialog/DialogEntity";

export default function DialogsContainer(props: Readonly<{ container?: HTMLElement }>) {
	const dialogs = useAppSelector(s => s.dialog.dialogs);
	if (dialogs.length === 0) {
		return null;
	}

	return (
		<>
			{dialogs.map(dialog => (
				<MedeLegacyDialog dialog={dialog} key={getDialogKey(dialog)} container={props.container} />
			))}
		</>
	);
}

function getDialogKey(dialog: DialogEntity) {
	const dialogKey = dialog.settings.url ?? dialog.settings.headerText ?? dialog.settings.messageText ?? "";
	return `${dialog.id}_${dialog.dialogType}_${dialogKey}`;
}
