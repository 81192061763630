import { AlertPriority, IAlertModel, IAlertDialogModel, IAlertInsights } from "@components/Alerts/AlertModel";

export const defaultAlertsState = {
	isLoading: true,
	createDialogProps: {
		isOpen: false,
		data: null as IAlertDialogModel | null
	},
	alerts: [
		{ id: 1, isRead: false, isTriggered: true, priority: AlertPriority.Info, metric: {} },
		{ id: 2, isRead: false, isTriggered: true, priority: AlertPriority.Info, metric: {} },
		{ id: 3, isRead: true, isTriggered: true, priority: AlertPriority.Info, metric: {} },
		{ id: 4, isRead: true, isTriggered: true, priority: AlertPriority.Info, metric: {} }
	] as IAlertModel[],
	insights: {
		data: [
			{ name: "1", title: "Insight item1" },
			{ name: "2", title: "Insight item2" }
		] as Array<IAlertInsights>,
		isLoaded: false
	}
};
