import history from "history/browser";
import { BreadcrumbStoredItem } from "../Breadcrumb/Breadcrumbs";
import { MENU_BUCKET_CAPTION_SESSION_KEY, PRODUCT_ID_SESSION_KEY, UPDATE_MENU_CACHE_KEY } from "@common/constants";
import { IProductAndBucket } from "@common/common-types";
import { getDataFromSession, saveDataInSession } from "@mede/react-library/utils";

export function saveBreadcrumbInState(
	breadCrumbHistory: BreadcrumbStoredItem[],
	skipNavigation: boolean = false
): void {
	const breadcrumbUrl = breadCrumbHistory[breadCrumbHistory.length - 1]?.uri;
	const url = breadcrumbUrl && !skipNavigation ? breadcrumbUrl : window.location.href;
	const state = { ...(history.location.state as object), breadcrumb: breadCrumbHistory };

	history.replace(url, state);
}

export function getBreadcrumbFromState(): BreadcrumbStoredItem[] | null {
	return (history.location.state as { breadcrumb?: BreadcrumbStoredItem[] })?.breadcrumb ?? null;
}

export function saveProductAndBucketInState(productAndBucket: IProductAndBucket) {
	const state = { ...(history.location.state as object), ...productAndBucket };

	history.replace(window.location.href, state);
}

export function saveProductInState(productId: string) {
	const state = { ...(history.location.state as object), productId };

	history.replace(window.location.href, state);
}

export function saveMenuBucketInState(menuBucketCaption: string) {
	const state = { ...(history.location.state as object), menuBucketCaption };

	history.replace(window.location.href, state);
}

export function getProductAndBucketFromState(): IProductAndBucket | null {
	return history.location.state as IProductAndBucket;
}

export function getProductAndBucketFromSession(): IProductAndBucket | null {
	return {
		productId: getDataFromSession<string>(PRODUCT_ID_SESSION_KEY),
		menuBucketCaption: getDataFromSession<string>(MENU_BUCKET_CAPTION_SESSION_KEY)
	};
}

export function saveCaptionInHistory(caption: string): void {
	const state = { ...(history.location.state as object), caption: caption };
	history.replace(window.location.href, state);
}

export function getMenuBucketCaption(): string | null {
	return getMenuBucketCaptionFromHistoryState() ?? getDataFromSession<string>(MENU_BUCKET_CAPTION_SESSION_KEY) ?? null;
}

function getMenuBucketCaptionFromHistoryState(): string | null {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (history.location.state as any)?.menuBucketCaption || null;
}

export function getRelativePath() {
	return window.location.pathname;
}

export function getRelativeUrl() {
	return window.location.pathname + window.location.search;
}

export function resetProduct() {
	sessionStorage.removeItem(PRODUCT_ID_SESSION_KEY);
	saveDataInSession(UPDATE_MENU_CACHE_KEY, true);
}
