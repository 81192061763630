import { getJson, postText, postJson, IFetchOptions, fetchPost } from "@mede/react-library/utils";
import { IAppConfiguration } from "@common/common-types";
import {
	AuthenticationState,
	ISessionExpirationConfig
} from "@components/App/SessionExpiration/SessionExpirationTypes";

export namespace loginApi {
	const requestInit: RequestInit = {
		credentials: "include",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded"
		}
	};

	export async function logOut(): Promise<boolean> {
		const result = await postText("LoginService.asmx/LogoutCurrentSession", null, { requestInit });
		return result?.includes("true");
	}

	export function logIn(username: string, password: string): Promise<Response> {
		const options: IFetchOptions = {
			requestInit: {
				...requestInit,
				body: `notParsedLogin=${username}&password=${password}`
			}
		};

		return fetchPost("LoginService.asmx/AuthorizeAccount", null, options);
	}

	export function getConfiguration(initial: boolean): Promise<IAppConfiguration> {
		const options: IFetchOptions = {
			requestInit: {
				cache: "no-store",
				priority: "high"
			},
			suppressError: true
		};

		return getJson<IAppConfiguration>(
			"platform-api/configuration/get",
			{ initial: initial.toString(), v: "2" },
			options
		);
	}

	export function getSessionExpirationConfig(): Promise<ISessionExpirationConfig> {
		return getJson<ISessionExpirationConfig>("platform-api/configuration/expiration-model");
	}

	export async function continueSession(): Promise<void> {
		await postText("Admin/SessionExpiration.mvc/ContinueSession", null, { requestInit });
	}

	export async function checkPassword(password: string): Promise<AuthenticationState> {
		const response = await postJson<{ d: AuthenticationState }>("SessionExpirationChecker.asmx/CheckPassword", {
			password
		});
		return response.d;
	}
}
