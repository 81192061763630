import { createSlice, PayloadAction, type WithSlice } from "@reduxjs/toolkit";
import initialState from "../../redux/initialState";
import { IFactor, IGoal, IInitiativeDialogModel, IInitiativeModel, ILeader, IOrganization } from "./InitiativesModel";
import { injectSlice } from "@redux/store";

const initiativesSlice = createSlice({
	name: "initiatives",
	initialState: initialState.initiatives,
	reducers: {
		setInitiativesLoading(state, action: PayloadAction<boolean>) {
			state.isLoading = action.payload;
		},
		setInitiativesRestricted(state, action: PayloadAction<boolean>) {
			state.isRestricted = action.payload;
		},
		setInsightInitiatives(state, action: PayloadAction<{ insightId: string; initiatives: Array<IInitiativeModel> }>) {
			const { insightId, initiatives } = action.payload;
			state.isLoading = false;
			state.initiatives[insightId] = initiatives;
		},
		setInitiativeDialogData(state, action: PayloadAction<IInitiativeDialogModel>) {
			state.createDialogProps.data = action.payload;
			state.createDialogProps.isOpen = true;
		},
		setMetricToInitiativeDialogData(state, action: PayloadAction<IInitiativeDialogModel>) {
			state.addMetricDialogProps.data = action.payload;
			state.addMetricDialogProps.isOpen = true;
		},
		closeMetricToInitiativeDialog(state) {
			state.addMetricDialogProps.isOpen = false;
		},
		closeInitiativeDialog(state) {
			state.createDialogProps.isOpen = false;
		},
		setInitiativeOrganizations(state, action: PayloadAction<IOrganization[]>) {
			state.organizations = action.payload;
		},
		setInitiativeOrganizationGoals(state, action: PayloadAction<{ organizationId: number; goals: IGoal[] }>) {
			const { organizationId, goals } = action.payload;
			state.goals[organizationId] = goals;
		},
		setInitiativesByGoals(state, action: PayloadAction<{ planId: number; goalId: number; initiatives: IFactor[] }>) {
			const { planId, goalId, initiatives } = action.payload;
			state.initiativesByGoals[`${planId}_${goalId}`] = initiatives;
		},
		setInitiativeOrganizationLeaders(state, action: PayloadAction<{ organizationId: number; leaders: ILeader[] }>) {
			const { organizationId, leaders } = action.payload;
			state.leaders[organizationId] = leaders;
		}
	}
});

export const {
	setInitiativesLoading,
	setInsightInitiatives,
	setInitiativeDialogData,
	setMetricToInitiativeDialogData,
	setInitiativeOrganizations,
	setInitiativeOrganizationGoals,
	setInitiativeOrganizationLeaders,
	setInitiativesByGoals,
	setInitiativesRestricted
} = initiativesSlice.actions;

export const { closeInitiativeDialog, closeMetricToInitiativeDialog } = initiativesSlice.actions;

export default initiativesSlice.reducer;

declare module "@redux/store" {
	// eslint-disable-next-line @typescript-eslint/no-empty-object-type
	export interface LazyLoadedSlices extends WithSlice<typeof initiativesSlice> {}
}

injectSlice(initiativesSlice);
