import { IInsightBookDto, IInsightPanelMetric, IInsightPanelWidget } from "@components/Insights/Panel/InsightModel";
import {
	IAnalystBoardConfig,
	IAnalystDriverMetrics,
	IInsightFilter,
	IInsightFilterMetadata,
	IAnalystFilterConfigDto,
	IInsightAnalystModel
} from "@components/Insights/Analyst/AnalystModel";
import { getAnalystPageSearchParam } from "@components/Insights/Analyst/AnalystSearchParamHelper";
import { IAxisWithMembers } from "@components/Filters/AxisModel";

const fakeInsightWidget = {
	isLoaded: false,
	title: "Widget",
	primary: {} as IInsightPanelMetric,
	secondary: {} as IInsightPanelMetric,
	description: "Description of widget",
	initiatives: [] as Array<string>
};

const fakeHeroMetric = {
	value: "1"
};

export interface IInsightPanelModel {
	isConfigLoaded: boolean;
	isMetricsLoaded: boolean;
	reportId: string;
	stateId: string;
	metrics: IInsightPanelMetric[];
	widgets: IInsightPanelWidget[];
	filtersCfg: IAnalystFilterConfigDto[];
	filters: IInsightFilter[];
	filtersMetadata: IInsightFilterMetadata[];
	axesMetadataByIds: Dictionary<IAxisWithMembers>;
}

export const defaultInsightPanelData = {
	isConfigLoaded: false,
	isMetricsLoaded: false,
	reportId: null as string | null,
	stateId: null as string | null,
	metrics: [
		{ ...fakeHeroMetric, id: "1" },
		{ ...fakeHeroMetric, id: "2" },
		{ ...fakeHeroMetric, id: "3" }
	] as IInsightPanelMetric[],
	widgets: [
		{ ...fakeInsightWidget, id: "1" },
		{ ...fakeInsightWidget, id: "2" },
		{ ...fakeInsightWidget, id: "3" },
		{ ...fakeInsightWidget, id: "4" },
		{ ...fakeInsightWidget, id: "5" },
		{ ...fakeInsightWidget, id: "6" },
		{ ...fakeInsightWidget, id: "7" }
	] as IInsightPanelWidget[],
	books: [] as IInsightBookDto[],
	filtersCfg: [] as IAnalystFilterConfigDto[],
	filters: [] as IInsightFilter[],
	filtersMetadata: [] as IInsightFilterMetadata[],
	axesMetadataByIds: {} as Dictionary<IAxisWithMembers>
};

export const defaultInsightLibraryData = {
	isLoaded: false,
	widgets: [
		{ ...fakeInsightWidget, id: "1" },
		{ ...fakeInsightWidget, id: "2" },
		{ ...fakeInsightWidget, id: "3" },
		{ ...fakeInsightWidget, id: "4" },
		{ ...fakeInsightWidget, id: "5" },
		{ ...fakeInsightWidget, id: "6" }
	] as IInsightPanelWidget[]
};

const searchParams = new URLSearchParams(location.search);
const pageFromQuery = getAnalystPageSearchParam(searchParams);

export const defaultInsightAnalystData = {
	insightId: null,
	page: pageFromQuery,
	title: "\xa0",
	description: "",
	driverMetrics: {
		metrics: [{ name: "1", caption: "Metric name" }],
		selectedMetric: null,
		isLoaded: false
	} as IAnalystDriverMetrics,
	widgets: {},
	config: null as IAnalystBoardConfig | null,
	showMoreDialog: { isOpen: false }
} as IInsightAnalystModel;
