import { useAppDispatch, useAppSelector } from "@redux/reduxHooks";
import { useEffect } from "react";
import { AuthServiceFactory } from "@components/Initiatives/api/auth/AuthServiceFactory";
import { setInitiativesRestricted } from "@components/Initiatives/initiativesSlice";
import { isInsightsEpmEnabled } from "@epm/epmUtils";

export default function useInitiatives() {
	const dispatch = useAppDispatch();
	const isLoggedIn = useAppSelector(s => s.app.isLoggedIn);
	const config = useAppSelector(s => s.app.configuration.epm);
	const isReady = useAppSelector(s => s.app.configuration.hasAccess && isInsightsEpmEnabled(s.app.configuration.epm));

	useEffect(() => {
		if (!isReady || !isLoggedIn || config == null) {
			return;
		}

		const errorHandler = (reason: unknown) => {
			if (reason === "TokenInvalid") {
				dispatch(setInitiativesRestricted(true));
			}

			throw reason;
		};

		AuthServiceFactory.create(config.servicePath, config.ownerCode, config.orgUnit)
			.makeAuth()
			.then(() => dispatch(setInitiativesRestricted(false)))
			.catch(errorHandler);
	}, [isReady, isLoggedIn, config]);
}
