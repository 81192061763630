import { IFavoritesMenuData, IMenuModel, IProductItemDto, ISwitchProductDto, ProductType } from "./MenuModel";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { buildClientTitle, isBackForwardNavigation, setDocumentTitle } from "@common/utils";
import { getDataFromLocalStorage, getDataFromSession, nonEmpty, saveDataInSession } from "@mede/react-library/utils";
import { IPageData, IProductAndBucket } from "@common/common-types";
import {
	NOT_VIEWED_ALERTS_KEY,
	BREADCRUMB_SESSION_KEY,
	NOT_VIEWED_COLLABORATIONS_KEY,
	MENU_BUCKET_CAPTION_SESSION_KEY,
	MENU_SESSION_KEY,
	PRODUCT_ID_SESSION_KEY,
	UPDATE_MENU_CACHE_KEY
} from "@common/constants";
import { BreadcrumbStoredItem } from "../Breadcrumb/Breadcrumbs";
import { LegacyAppUrls } from "@legacyApp/LegacyAppUrls";
import { NavigateFunction } from "react-router-dom";
import initialState, { isDefaultMenu } from "@redux/initialState";
import { AppDispatch, RootState } from "@redux/store";
import { menuApi } from "./menuApi";
import {
	getProductAndBucketFromSession,
	getProductAndBucketFromState,
	getRelativePath,
	saveBreadcrumbInState,
	saveCaptionInHistory,
	saveMenuBucketInState,
	saveProductAndBucketInState,
	saveProductInState
} from "./menuHistoryStateUtils";
import { SearchMenuService } from "./searchMenuService";
import { openDialog } from "@components/Dialog/dialogSlice";
import { DialogType } from "@components/Dialog/DialogEntity";
import { setEventsAsViewedBeacon } from "@common/commonApi";
import { logOut, setAppLoading } from "@components/App/appSlice";
import { isFavoriteUrl, isRootMenuItem } from "@components/Menu/menuNavigationUtils";
import { menuEventTracerService } from "@components/Menu/menuEventTracerService";
import { eventTracerApi } from "@common/eventTracerApi";

const CONFIRM_LEAVING_MESSAGE = "Are you sure you want to leave this page? All unsaved changes will be lost.";

const menuSlice = createSlice({
	name: "menu",
	initialState: initialState.menu,
	reducers: {
		menuLoaded: {
			reducer(state, action: PayloadAction<{ menu: IMenuModel; isLocalCache: boolean }>) {
				const { menu, isLocalCache } = action.payload;
				state.menuData = menu;
				state.isLoading = false;

				const productDataFromState = getProductAndBucketFromState();
				const productDataFromSession = getProductAndBucketFromSession();
				state.menuBucketCaption =
					nonEmpty(productDataFromState?.menuBucketCaption) ?? productDataFromSession?.menuBucketCaption ?? "";

				const products = state.menuData.productsData.products;
				state.product =
					findProduct(products, state.product?.id) ??
					findProduct(products, productDataFromState?.productId) ??
					findProduct(products, productDataFromSession?.productId) ??
					findProduct(products, state.menuData.productsData.currentProductId);

				const productData: IProductAndBucket = {
					productId: state.product?.id,
					menuBucketCaption: state.menuBucketCaption
				};

				if (!isLocalCache) {
					saveProductAndBucketInState(productData);
					saveDataInSession(PRODUCT_ID_SESSION_KEY, productData.productId);
					saveDataInSession(MENU_BUCKET_CAPTION_SESSION_KEY, productData.menuBucketCaption);
				}
			},
			prepare(menu: IMenuModel, isLocalCache: boolean = false) {
				return { payload: { menu, isLocalCache } };
			}
		},
		favoritesLoaded(state, action: PayloadAction<IFavoritesMenuData>) {
			const data = action.payload;
			state.menuData.productsData.personalFavoriteItems = data.personalFavoriteItems;
			state.menuData.productsData.organizationalFavoriteItems = data.organizationalFavoriteItems;
			state.menuData.productsData.personalCustomDetailsItems = data.personalCustomDetailsItems;
			state.menuData.productsData.organizationalCustomDetailsItems = data.organizationalCustomDetailsItems;
		},
		setMenuLoading(state, action: PayloadAction<boolean>) {
			state.isLoading = action.payload;
		},
		setProductAndMenuPath: {
			reducer(state, action: PayloadAction<{ productId?: string; menuPath: string[]; caption: string }>) {
				const { productId, menuPath, caption } = action.payload;

				if (productId) {
					const product = findProduct(state.menuData.productsData.products, productId);
					if (product?.type === ProductType.ExternalApp) {
						return;
					}

					state.product = product;
				}

				state.menuBucketCaption = menuPath?.length ? menuPath[0] : "";

				saveDataInSession(
					PRODUCT_ID_SESSION_KEY,
					productId ?? state.product?.id ?? getProductAndBucketFromState()?.productId ?? ""
				);
				saveDataInSession(MENU_BUCKET_CAPTION_SESSION_KEY, state.menuBucketCaption);

				const breadcrumbHistory: BreadcrumbStoredItem[] = [];
				if (menuPath.length > 0) {
					breadcrumbHistory.push({ ...new BreadcrumbStoredItem(caption, menuPath.slice(1)) });
				}

				saveDataInSession(BREADCRUMB_SESSION_KEY, breadcrumbHistory);
				state.breadcrumbHistory = breadcrumbHistory;
			},
			prepare(menuPath: string[], caption: string, productId?: string) {
				return { payload: { productId, menuPath, caption } };
			}
		},
		setBreadcrumb: {
			reducer(state, action: PayloadAction<{ breadcrumb: BreadcrumbStoredItem[]; skipNavigation: boolean }>) {
				const { breadcrumb, skipNavigation } = action.payload;
				saveDataInSession(BREADCRUMB_SESSION_KEY, breadcrumb);
				saveBreadcrumbInState(breadcrumb, skipNavigation);
				setProductHistoryStateIfMissing(state.product?.id ?? null, state.menuBucketCaption);
				state.breadcrumbHistory = breadcrumb;
			},
			prepare(breadcrumb: BreadcrumbStoredItem[], skipNavigation: boolean = false) {
				// Ensure breadcrumb is serializable
				return {
					payload: {
						breadcrumb: breadcrumb.map(x => {
							return { ...x };
						}),
						skipNavigation
					}
				};
			}
		},
		clearBreadcrumbForNavigation(state) {
			saveDataInSession(BREADCRUMB_SESSION_KEY, []);
			state.breadcrumbHistory = [];
		},
		setSwitchProduct(state, action: PayloadAction<ISwitchProductDto>) {
			state.menuData.logo = action.payload.logo;
			state.menuData.productsData.currentProductId = action.payload.productId;
			saveMenuCache(state.menuData);
		},
		setMenuBucketCaption(state, action: PayloadAction<string>) {
			state.menuBucketCaption = action.payload;

			saveMenuBucketInState(action.payload);
			saveDataInSession(MENU_BUCKET_CAPTION_SESSION_KEY, action.payload);
		},
		setBreadcrumbDiscover(state, action: PayloadAction<BreadcrumbStoredItem | null>) {
			state.expectedBreadcrumbDiscover = action.payload;
		},
		updateCurrentProduct: {
			reducer(state, action: PayloadAction<{ productId: string }>) {
				const { productId } = action.payload;
				if (productId) {
					state.product = findProduct(state.menuData.productsData.products, productId);

					saveProductInState(productId);
					saveDataInSession(PRODUCT_ID_SESSION_KEY, productId);
				}
			},
			prepare(productId: string) {
				return { payload: { productId } };
			}
		},
		setAlertsCount(state, action: PayloadAction<number>) {
			state.alertsCount = action.payload;
			saveDataInSession(NOT_VIEWED_ALERTS_KEY, action.payload);
		},
		decrementAlertsCount(state) {
			if (state.alertsCount > 0) {
				state.alertsCount = state.alertsCount - 1;
				saveDataInSession(NOT_VIEWED_ALERTS_KEY, state.alertsCount);
			}
		},
		setCollaborations(state, action: PayloadAction<number[]>) {
			state.collaborations = action.payload;
			saveDataInSession(NOT_VIEWED_COLLABORATIONS_KEY, action.payload);
		},
		setProductById(state, action: PayloadAction<string>) {
			const productId = action.payload;
			if (productId === state.product?.id) {
				return;
			}

			const products = state.menuData.productsData.products;
			const product = findProduct(products, productId);
			if (product != null) {
				state.product = product;
			}

			saveDataInSession(PRODUCT_ID_SESSION_KEY, productId);
		},
		setProduct(state, action: PayloadAction<IProductItemDto>) {
			state.product = action.payload;
			saveDataInSession(PRODUCT_ID_SESSION_KEY, action.payload.id);
		}
	}
});

const { setBreadcrumbDiscover } = menuSlice.actions;
export const {
	menuLoaded,
	favoritesLoaded,
	setProductAndMenuPath,
	setBreadcrumb,
	setSwitchProduct,
	updateCurrentProduct,
	setMenuBucketCaption,
	setAlertsCount,
	decrementAlertsCount,
	setCollaborations,
	setMenuLoading,
	setProductById,
	setProduct,
	clearBreadcrumbForNavigation
} = menuSlice.actions;
export default menuSlice.reducer;

export function loadMenu(updateCache: boolean = false, showProcessing: boolean = false) {
	return async (dispatch: AppDispatch, getState: () => RootState) => {
		const state = getState();
		updateCache = updateCache || getDataFromSession(UPDATE_MENU_CACHE_KEY) === true;
		const menuCache = getDataFromSession<IMenuModel>(MENU_SESSION_KEY);
		if (menuCache && !updateCache) {
			const currentProductId = getDataFromSession<string>(PRODUCT_ID_SESSION_KEY);
			dispatch(menuLoaded(menuCache));
			dispatchDiscoverIfExpected(dispatch, state);
			await switchProductOnBackForwardNavigation(currentProductId, dispatch);
			return;
		}

		if (!updateCache && !showProcessing) {
			dispatch(loadStorageCachedMenu());
		}

		if (!state.app.configuration.hasAccess) {
			return;
		}

		if (showProcessing) {
			dispatch(setMenuLoading(true));
		}

		try {
			const menuData = await menuApi.getMenu(updateCache);
			saveMenuCache(menuData);
			sessionStorage.removeItem(UPDATE_MENU_CACHE_KEY);
			refreshProductOnMenuLoaded(menuData, dispatch);
			dispatch(menuLoaded(menuData));
			dispatchDiscoverIfExpected(dispatch, state);
		} catch {
			console.error("Failed to get menu. Trying again...");
			setTimeout(() => {
				dispatch(loadMenu(updateCache, showProcessing));
			}, 500);
		}
	};
}

function refreshProductOnMenuLoaded(menuData: IMenuModel, dispatch: AppDispatch) {
	const productId = getDataFromSession<string>(PRODUCT_ID_SESSION_KEY);
	if (menuData.productsData.currentProductId === productId) {
		return;
	}

	const product = findProduct(menuData.productsData.products, menuData.productsData.currentProductId);
	if (product == null) {
		return;
	}

	dispatch(setProduct(product));

	if (location.href.includes(LegacyAppUrls.landingPage)) {
		location.reload();
	}
}

async function switchProductOnBackForwardNavigation(currentProductId: string | null, dispatch: AppDispatch) {
	if (!isBackForwardNavigation()) {
		return;
	}

	const productIdFromState = getProductAndBucketFromState()?.productId;
	if (productIdFromState == null || productIdFromState === currentProductId) {
		return;
	}

	const switchProduct = await menuApi.switchProduct(productIdFromState, true);
	dispatch(setSwitchProduct(switchProduct));
}

function loadStorageCachedMenu() {
	return (dispatch: AppDispatch, getState: () => RootState) => {
		const state = getState();
		const authId = state.app.configuration.authId;
		const lastAuthId = state.app.lastAuthId;
		if (authId !== lastAuthId) {
			return;
		}

		const menuCache = getDataFromLocalStorage<IMenuModel>(MENU_SESSION_KEY);
		if (menuCache != null) {
			dispatch(menuLoaded(menuCache, true));
			dispatchDiscoverIfExpected(dispatch, state);
		}
	};
}

export function loadFavorites() {
	return async (dispatch: AppDispatch) => {
		const favoritesMenuData = await menuApi.getFavorites(true);
		dispatch(favoritesLoaded(favoritesMenuData));

		const menuCache = getDataFromSession<IMenuModel>(MENU_SESSION_KEY);
		if (menuCache == null) {
			return;
		}

		menuCache.productsData = { ...menuCache.productsData, ...favoritesMenuData };
		saveMenuCache(menuCache);
	};
}

function dispatchDiscoverIfExpected(dispatch: AppDispatch, state: RootState): void {
	if (state.menu.expectedBreadcrumbDiscover != null) {
		dispatch(discoverBreadcrumb(state.menu.expectedBreadcrumbDiscover));
		dispatch(setBreadcrumbDiscover(null));
	}
}

export function switchProductAndLoadMenu(newProductId: string) {
	return async (dispatch: AppDispatch, getState: () => RootState) => {
		const logo = getState().menu.menuData.logo;
		const storedProductId = getDataFromSession<string>(PRODUCT_ID_SESSION_KEY);
		let updateCache = false;
		let switchProduct: ISwitchProductDto | null = null;
		if (storedProductId !== newProductId) {
			try {
				switchProduct = await menuApi.switchProduct(newProductId, true);
				updateCache =
					logo == null ||
					switchProduct.logo.logoHref !== logo.logoHref ||
					switchProduct.logo.imageSrc !== logo.imageSrc;
			} catch {
				// Don't switch product if no access
			}
		}

		if (switchProduct) {
			saveProductInState(newProductId);
			saveDataInSession(PRODUCT_ID_SESSION_KEY, newProductId);
		}

		await dispatch(loadMenu(updateCache));
		if (switchProduct) {
			dispatch(setSwitchProduct(switchProduct));
		}

		dispatch(updateCurrentProduct(switchProduct?.productId ?? newProductId));
	};
}

export function switchProductForNavigation(newProductId: string) {
	return async (dispatch: AppDispatch, getState: () => RootState) => {
		await switchProductIfNeeded(newProductId, getState(), dispatch);
		dispatch(setProductById(newProductId));
	};
}

export function discoverBreadcrumb(breadcrumbItem: BreadcrumbStoredItem) {
	return async (dispatch: AppDispatch, getState: () => RootState) => {
		if (isFavoriteUrl(breadcrumbItem.uri)) {
			return;
		}

		// Postpone breadcrumb discover until menu is loaded
		const state = getState();
		if (state.menu.isLoading) {
			dispatch(setBreadcrumbDiscover(breadcrumbItem));
			return;
		}

		const searchMenuService = new SearchMenuService();
		const menu = state.menu;
		const productId = menu.product?.id;
		const foundItem = searchMenuService.findPageInMenu(
			menu.menuData.productsData.productMenuItems,
			breadcrumbItem.uri,
			productId
		);
		if (foundItem == null) {
			dispatch(setMenuBucketCaption(""));
			dispatch(setBreadcrumb([breadcrumbItem]));
			return;
		}

		dispatch(setMenuBucketCaption(foundItem.path[0]));

		if (foundItem.menuItem.productId != null && foundItem.menuItem.productId !== productId) {
			await dispatch(switchProductAndLoadMenu(foundItem.menuItem.productId));
		}

		const foundCaption = foundItem.menuItem.caption!;
		const breadcrumbPath = [...foundItem.path.slice(1), foundCaption];
		const updatedBreadcrumbItem = { ...breadcrumbItem, caption: foundCaption };
		document.title = buildClientTitle(state.app.configuration.clientTitle, foundCaption);
		saveCaptionInHistory(document.title);

		dispatch(
			setBreadcrumb([
				{
					...updatedBreadcrumbItem,
					path: breadcrumbPath
				}
			])
		);
	};
}

export function setPage(
	data: IPageData | null,
	path: string[],
	caption: string,
	navigate: NavigateFunction,
	productId?: string
) {
	return async (dispatch: AppDispatch, getState: () => RootState) => {
		if (!data) {
			return;
		}

		switch (data.page) {
			case "RefreshTopMenu":
				dispatch(loadMenu(true, true));
				return;
			case "UserGroups":
				openUserGroups(dispatch);
				return;
			case "AddCollaboration":
				openAddCollaboration(dispatch);
				return;
		}

		const state = getState();
		if (!(await confirmLeaving(state, dispatch))) {
			dispatch(setAppLoading(false));
			return;
		}

		const isLegacy = state.app.isLegacyAppMode;

		if (!data.isNewWindow) {
			dispatch(setProductAndMenuPath(path, caption, productId));
		}

		switch (data.page.toLowerCase()) {
			case "external":
				await switchProductIfNeeded(productId, state, dispatch);
				openExternalPage(data.url, dispatch, data.isNewWindow);
				return;
			case "home":
				await switchProductIfNeeded(productId, state, dispatch);
				openExternalPage(LegacyAppUrls.landingPage, dispatch);
				return;
			case "logout":
				dispatch(logOut());
				return;
			case "collaborations":
				setEventsAsViewedBeacon(state.menu.collaborations);
				dispatch(setCollaborations([]));
				openExternalPage(data.url, dispatch, data.isNewWindow);
				return;
		}

		await switchProductIfNeeded(productId, state, dispatch);
		navigateToReactPage(isLegacy, data, dispatch, navigate);
	};
}

export function pageLoaded(pageCaption: string, replaceHistory: boolean = false) {
	return (dispatch: AppDispatch, getState: () => RootState) => {
		const state = getState();
		const currentBreadcrumbHistory = state.menu.breadcrumbHistory;
		const urlPath = getRelativePath();
		let lastBreadcrumbItemIndex = currentBreadcrumbHistory.findIndex(
			x => x.uri != null && new URL(x.uri, location.origin).pathname == urlPath
		);

		const newBreadcrumbHistory =
			lastBreadcrumbItemIndex == -1
				? [...currentBreadcrumbHistory]
				: currentBreadcrumbHistory.slice(0, lastBreadcrumbItemIndex + 1);

		const newBreadcrumbItem = new BreadcrumbStoredItem(pageCaption, pageCaption, urlPath);
		if (!isFavoriteUrl(urlPath) && !isRootMenuItem(urlPath) && newBreadcrumbHistory.length == 0) {
			dispatch(discoverBreadcrumb(newBreadcrumbItem));
			return;
		}

		if (lastBreadcrumbItemIndex < 0 && !replaceHistory) {
			newBreadcrumbHistory.push(newBreadcrumbItem);
		} else {
			if (lastBreadcrumbItemIndex == -1) {
				lastBreadcrumbItemIndex = newBreadcrumbHistory.length - 1;
			}

			const lastBreadcrumbItem = newBreadcrumbHistory[lastBreadcrumbItemIndex];
			newBreadcrumbHistory[lastBreadcrumbItemIndex] = {
				...lastBreadcrumbItem,
				caption: pageCaption,
				uri: newBreadcrumbItem.uri,
				path: [...lastBreadcrumbItem.path.slice(0, -1), pageCaption]
			};
		}

		setDocumentTitle(state.app.configuration.clientTitle, pageCaption);
		dispatch(setBreadcrumb(newBreadcrumbHistory));
		menuEventTracerService.traceNavigation();
	};
}

export function updateFavoriteUsage(favoriteItemId: string) {
	return async (dispatch: AppDispatch) => {
		await eventTracerApi.updateFavoriteUsage(favoriteItemId);
		await dispatch(loadFavorites());
	};
}

export function navigateTo(url: string) {
	return async (dispatch: AppDispatch, getState: () => RootState) => {
		const state = getState();

		if (!(await confirmLeaving(state, dispatch))) {
			dispatch(setAppLoading(false));
			return;
		}

		window.open(url, "_self");
	};
}

async function confirmLeaving(state: RootState, dispatch: AppDispatch): Promise<boolean> {
	if (!window.isLeaveConfirmationNeeded?.() && !state.app.leavingConfirmationNeeded) {
		return true;
	}

	return new Promise<boolean>(resolve => {
		dispatch(
			openDialog(DialogType.Confirm, {
				headerText: "Discard Unsaved Changes",
				messageText: CONFIRM_LEAVING_MESSAGE,
				okButtonText: "Discard",
				okButtonIntent: "danger",
				returnResultCallback: (result: { confirmValue: boolean }) => {
					resolve(result.confirmValue);
				}
			})
		);
	});
}

async function switchProductIfNeeded(productId: string | undefined, state: RootState, dispatch: AppDispatch) {
	const oldProductId = state.menu.product?.id;
	if (productId == null || productId.length === 0 || productId === oldProductId) {
		return;
	}

	const product = findProduct(state.menu.menuData.productsData.products, productId);
	if (product?.preventSwitchProduct) {
		return;
	}

	dispatch(setAppLoading(true));
	const switchedProduct = await menuApi.switchProduct(productId);
	dispatch(setSwitchProduct(switchedProduct));

	if (state.menu.menuData.productsData.productMenuItems[productId] == null) {
		saveDataInSession(UPDATE_MENU_CACHE_KEY, true);
	}
}

function openExternalPage(url: string | undefined, dispatch: AppDispatch, isNewWindow: boolean = false): void {
	if (!url) {
		return;
	}

	if (!isNewWindow && !isHashNavigation(url)) {
		dispatch(setAppLoading(true));
	}

	const target = isNewWindow ? "_blank" : "_self";
	window.open(url, target);
}

function isHashNavigation(url: string) {
	const targetUrl = new URL(url, location.origin);
	if (targetUrl.hash.length < 2) {
		return false;
	}

	return targetUrl.pathname === location.pathname;
}

function openUserGroups(dispatch: AppDispatch): void {
	dispatch(
		openDialog(DialogType.Frame, {
			url: LegacyAppUrls.userGroups,
			width: 550,
			height: 600
		})
	);
}

function openAddCollaboration(dispatch: AppDispatch): void {
	const model = window.collaborationModel;
	if (model == null) {
		return;
	}

	const params = Object.keys(model)
		.map(function (key) {
			return key + "=" + encodeURIComponent(model[key]);
		})
		.join("&");

	dispatch(
		openDialog(DialogType.Frame, {
			url: LegacyAppUrls.addCollaborationDialog + params,
			width: 580,
			height: 600
		})
	);
}

function navigateToReactPage(isLegacy: boolean, data: IPageData, dispatch: AppDispatch, navigate: NavigateFunction) {
	const appPath = data.url!.substring(data.url!.indexOf("app/") + 4);
	if (isLegacy) {
		openExternalPage("/app/" + appPath, dispatch);
	} else {
		dispatch(setAppLoading(false));
		navigate("/" + appPath);
	}
}

function findProduct(products: IProductItemDto[], productId: string | null | undefined): IProductItemDto | null {
	if (productId == null) {
		return null;
	}

	return products.find(x => x.id === productId) ?? null;
}

function saveMenuCache(menuData: IMenuModel): void {
	if (menuData == null || isDefaultMenu(menuData)) {
		return;
	}

	// Don't call saveDataInSession and saveDataInStorage to avoid converting to JSON twice.
	const jsonData = JSON.stringify(menuData);
	window.sessionStorage.setItem(MENU_SESSION_KEY, jsonData);
	window.localStorage.setItem(MENU_SESSION_KEY, jsonData);
}

function setProductHistoryStateIfMissing(productId: string | null, menuBucketCaption: string | null) {
	const productDataFromHistory = getProductAndBucketFromState();
	if (productDataFromHistory?.productId && productDataFromHistory.menuBucketCaption) {
		return;
	}

	const productDataFromSession = getProductAndBucketFromSession();
	saveProductAndBucketInState({
		productId: productDataFromHistory?.productId ?? productId ?? productDataFromSession?.productId,
		menuBucketCaption:
			nonEmpty(productDataFromHistory?.menuBucketCaption) ??
			nonEmpty(menuBucketCaption) ??
			nonEmpty(productDataFromSession?.menuBucketCaption) ??
			""
	});
}
