import { ActionProps, Intent, LinkProps, Toaster } from "@blueprintjs/core";
import { IUserEvent } from "@components/App/EventNotifier/IUserEvent";
import { ReactNode, SyntheticEvent } from "react";
import { TOAST, TOAST_CONTAINER } from "@blueprintjs/core/lib/esnext/common/classes";
import { setEventsAsViewed } from "@common/commonApi";
import { AppDispatch } from "@redux/store";
import { openDialog } from "@components/Dialog/dialogSlice";
import { DialogType } from "@components/Dialog/DialogEntity";
import { faCheck, faInfoCircle, faWarning, faCircleExclamation } from "@mede/react-library/icons";
import "@components/App/Toast.scss";
import { v4 as guid } from "uuid";
import { MedeIcon } from "@mede/react-library/components";

export enum NotificationType {
	Success,
	Info,
	Warning,
	Error
}

export class NotificationOptions {
	public timeout: number;

	public constructor(timeout: number) {
		this.timeout = timeout;
	}
}

const TOAST_SELECTOR = `.${TOAST_CONTAINER} .${TOAST}`;
const TOAST_DELAY = 200;

class NotificationManager {
	private readonly vanishNotViewedToasts: string[] = [];

	private toaster: Toaster | null = null;
	private toastContainer: HTMLElement = window.document.body;
	private events: Dictionary<{ toastKey: string; eventId: string }> = {};
	private timeout = 60;
	private lastActiveElement: HTMLElement | undefined;
	private dispatch: AppDispatch | null = null;
	private showTimeout = 0;

	constructor() {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(window as any).notificationManager = this;
	}

	// Change to static usage when Blueprint support React 18
	public setToaster(toaster: Toaster, container: HTMLElement, dispatch: AppDispatch): void {
		this.toaster = toaster;
		this.toastContainer = container;
		this.dispatch = dispatch;
	}

	public setTimeout(timeout: number): void {
		this.timeout = timeout;
	}

	public showDefaultError(): string | undefined {
		return this.showError("An error occurred. Try to repeat your action later.");
	}

	public showError(message: string): string | undefined {
		return this.showNotification(message, NotificationType.Error);
	}

	public showException(message: string, exceptionUrl: string | null) {
		if (!exceptionUrl) {
			return this.showError(message);
		}

		return this.showNotification(message, NotificationType.Error, {
			text: "View Error",
			href: exceptionUrl,
			target: "_blank"
		});
	}

	public showSuccess(message: string): void {
		this.showNotification(message, NotificationType.Success);
	}

	public showInfo(message: string): void {
		this.showNotification(message, NotificationType.Info);
	}

	public showWarning(message: string): void {
		this.showNotification(message, NotificationType.Warning);
	}

	public hide(toastId: string | undefined): void {
		if (toastId != null) {
			this.toaster?.dismiss(toastId);
		}
	}

	public showWithAction(
		message: string,
		actionCaption: string,
		actionUrl?: string,
		clickHandler?: () => void,
		type: NotificationType = NotificationType.Success,
		options?: NotificationOptions
	): void {
		this.showNotification(
			message,
			type,
			{
				text: actionCaption,
				href: actionUrl,
				onClick: clickHandler
			},
			() => {},
			options
		);
	}

	public showEvent(event: IUserEvent): void {
		if (this.toaster == null) {
			return;
		}

		const oldEvent = this.events[event.eventType];
		const eventId = event.eventIds.join(",");
		if (oldEvent != null) {
			if (oldEvent.eventId === eventId) {
				return;
			}

			this.vanishNotViewedToasts.push(oldEvent.toastKey);
			this.toaster.dismiss(oldEvent.toastKey);
		}

		const toastKey: string = this.showNotification(
			event.message,
			NotificationType.Success,
			{
				text: event.actionCaption,
				onClick: e => this.eventActionClick(e, event),
				href: "/" + event.actionUrl
			},
			didTimeoutExpire => {
				this.dismissEvent(didTimeoutExpire, event.eventIds, toastKey);
			}
		)!;

		this.events[event.eventType] = { toastKey, eventId };
	}

	private showNotification(
		message: string,
		type: NotificationType,
		action?: ActionProps & LinkProps,
		onDismiss?: (didTimeoutExpire: boolean) => void,
		options?: NotificationOptions
	): string | undefined {
		if (this.toaster == null) {
			return;
		}

		let thisToast: HTMLElement | null = null;
		const toastKey = guid();

		let timeout = this.timeout;
		if (options != null && timeout > 0) {
			timeout = options.timeout;
		}

		setTimeout(
			() => {
				this.toaster!.show(
					{
						...this.getToastStyle(type),
						message: this.parseMessage(message),
						timeout: timeout * 1000,
						action: action,
						className: "notification-entry",
						onDismiss: didTimeoutExpire => {
							this.focusNextToast(thisToast);
							onDismiss?.(didTimeoutExpire);
						}
					},
					toastKey
				);

				setTimeout(() => {
					thisToast = this.toastContainer.querySelector(TOAST_SELECTOR);
					// eslint-disable-next-line @typescript-eslint/no-unused-expressions
					thisToast && this.setupToastInteractions(thisToast, toastKey);
				}, 0);

				this.showTimeout -= TOAST_DELAY;
				if (this.showTimeout < 0) {
					this.showTimeout = 0;
				}
			},
			testMode ? 0 : this.showTimeout
		);

		this.showTimeout += TOAST_DELAY;

		return toastKey;
	}

	private getToastStyle(type: NotificationType): { intent: Intent; icon: JSX.Element } {
		switch (type) {
			case NotificationType.Success:
				return {
					intent: Intent.SUCCESS,
					icon: <MedeIcon icon={faCheck} />
				};
			case NotificationType.Info:
				return {
					intent: Intent.PRIMARY,
					icon: <MedeIcon icon={faInfoCircle} />
				};
			case NotificationType.Warning:
				return {
					intent: Intent.WARNING,
					icon: <MedeIcon icon={faWarning} />
				};
			case NotificationType.Error:
				return {
					intent: Intent.DANGER,
					icon: <MedeIcon icon={faCircleExclamation} />
				};
		}
	}

	private parseMessage(message: string): ReactNode {
		if (!message?.includes("<br")) {
			return message;
		}

		const lines = message.split(/<br ?\/ ?>/);
		return (
			<>
				{lines.map(line => (
					<div key={line}>{line}</div>
				))}
			</>
		);
	}

	private focusNextToast(thisToast: HTMLElement | null = null): void {
		const toasts = this.toastContainer.querySelectorAll(TOAST_SELECTOR);
		const nextToast = (toasts[0] === thisToast ? toasts[1] : toasts[0]) as HTMLElement;

		if (nextToast != null) {
			nextToast.focus();
		} else if (this.lastActiveElement != null) {
			this.lastActiveElement.focus();
		}
	}

	private setupToastInteractions(thisToast: HTMLElement, toastKey: string): void {
		let fadeOutTimer: number | undefined = undefined;
		thisToast.addEventListener("mouseout", () => {
			fadeOutTimer = setTimeout(() => {
				this.vanishNotViewedToasts.push(toastKey);
				this.toaster?.dismiss(toastKey);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			}, 1000) as any as number;
		});

		thisToast.addEventListener("mouseover", () => {
			if (fadeOutTimer) {
				clearInterval(fadeOutTimer);
				fadeOutTimer = undefined;
			}
		});

		thisToast.addEventListener("keyup", e => {
			if (e.key === "Escape") {
				this.toaster?.dismiss(toastKey);
			}
		});

		thisToast.addEventListener("focusin", e => {
			const activeElement = e.relatedTarget as HTMLElement;
			if (!this.toastContainer.contains(activeElement)) {
				this.lastActiveElement = activeElement;
			}
		});

		thisToast.focus();
	}

	private async dismissEvent(didTimeoutExpire: boolean, eventIds: number[], toastKey: string): Promise<void> {
		if (
			didTimeoutExpire ||
			eventIds == null ||
			eventIds.length === 0 ||
			this.vanishNotViewedToasts.includes(toastKey)
		) {
			return;
		}

		try {
			await setEventsAsViewed(eventIds);
		} catch {
			this.showWarning("Failed to mark event as viewed");
		}
	}

	private eventActionClick(e: SyntheticEvent, userEvent: IUserEvent): void {
		if (userEvent.isDialogAction) {
			e.preventDefault();
			this.openDownloadDialog("/" + userEvent.actionUrl);
		}
	}

	private openDownloadDialog(url: string): void {
		this.dispatch?.(
			openDialog(DialogType.Frame, {
				url: url,
				width: 480,
				height: 180,
				closeDialogCallback: () => this.focusNextToast()
			})
		);
	}
}

export const notificationManager = new NotificationManager();
