import { IMenuModel, LeftMenuNodeType } from "@components/Menu/MenuModel";

export const defaultMenuState = {
	productsData: {
		currentProductId: "_default_",
		products: [
			{
				id: "_default_",
				caption: "Default"
			},
			{
				id: "_default2_",
				caption: "Default2"
			}
		],
		productMenuItems: {
			_default_: {
				menuItems: [
					{
						caption: "Default 1"
					},
					{
						caption: "Default 2"
					},
					{
						caption: "Default 3"
					}
				]
			},
			_default2_: {}
		},
		productMenuCaption: "Product",
		productDropdownCaption: "",
		personalFavoriteItems: [
			{
				leftMenuNodeType: LeftMenuNodeType.MostUsedFolder,
				children: [
					{
						caption: ""
					}
				]
			}
		],
		organizationalFavoriteItems: [],
		personalCustomDetailsItems: [
			{
				leftMenuNodeType: LeftMenuNodeType.MostUsedFolder,
				children: [
					{
						caption: ""
					}
				]
			}
		],
		organizationalCustomDetailsItems: [
			{
				leftMenuNodeType: LeftMenuNodeType.MostUsedFolder,
				children: [
					{
						caption: ""
					}
				]
			}
		]
	},
	user: {
		userFullName: "Name surname",
		userEmail: "user@email.com",
		userItems: {}
	},
	logo: {},
	smallLogo: {},
	largeLogo: {},
	helpMenuItems: [],
	topUsedItems: [{ children: [] }, { children: [] }],
	notifications: [{ page: "Alerts" }, { page: "Collaborations" }],
	tools: [],
	settings: {
		hasAnyProductConfigured: true,
		hasMenuSearchAccess: true,
		hasHelpSupportMenuItemAccess: true,
		hasInsightsAccess: true
	}
} as never as IMenuModel;
