import { IInsightFilter, IInsightFilterInclude } from "./AnalystModel";

export const ANALYST_PAGE_SEARCH_PARAM = "p";
export const ANALYST_FILTER_SEARCH_PARAM = "f";

export const ANALYST_DRIVER_METRIC_SEARCH_PARAM = "dm";

export function getAnalystPageSearchParam(searchParams: URLSearchParams): number {
	const page = searchParams.get(ANALYST_PAGE_SEARCH_PARAM);
	if (page == null) {
		return 0;
	}

	return parseInt(page);
}

export function getAnalystFiltersSearchParam(searchParams: URLSearchParams): IInsightFilter[] {
	const filters = searchParams.get(ANALYST_FILTER_SEARCH_PARAM);
	if (filters == null) {
		return [];
	}

	return JSON.parse(filters).map(
		(x: IFilterSearchParam) =>
			({
				axisId: x.a,
				type: x.t == "e" ? "Equals" : "",
				arguments: x.m
			}) as IInsightFilterInclude
	);
}

export function getAnalystDriverMetric(searchParams: URLSearchParams): string | null {
	return searchParams.get(ANALYST_DRIVER_METRIC_SEARCH_PARAM);
}

export function simplifyFilters(filters: IInsightFilter[]): IFilterSearchParam[] {
	return filters.map(x => ({
		t: x.type == "Equals" ? "e" : "",
		a: x.axisId,
		m: (x as IInsightFilterInclude).arguments
	}));
}

interface IFilterSearchParam {
	t: "e" | ""; // Type
	a: string; // AxisId
	m: string[]; // Argument members
}
