import {
	FetchStatus,
	IColoredDataDto,
	IEnvironment,
	IFetchableItem,
	IFetchableItems,
	IOrganization,
	IPlanSet,
	IUser
} from "@epm/epmModel";
import { IIdentifiableWithCaption } from "@epm/Controls/BaseModels";
import {
	IActionStepsDataTableRowDto,
	IGoalDataTableRowDto,
	IHyperlinkDataTableRowDto,
	IInitiativesDataTableRowDto
} from "@components/Epm/Plan/DataTable/epmTableModels";
import { ILinkedItemDataTableRowDto } from "@epm/PlanEnterprise/DataTables/planEnterpriseDataTableModels";

export const EMPTY_PLAN_SET: IPlanSet = { planSetId: 0, name: "" };

function getDefaultFetchableItems<T>(): IFetchableItems<T> {
	return {
		items: [],
		fetchStatus: FetchStatus.Empty
	};
}

function getDefaultFetchableItem<T>(defaultItem: T): IFetchableItem<T> {
	return {
		item: defaultItem,
		fetchStatus: FetchStatus.Empty
	};
}

export const defaultEpmState: IEpmState = {
	initialized: false,
	environment: getDefaultFetchableItem({
		userInfo: null,
		defaultOrganizationId: null
	} as IEnvironment),
	terms: getDefaultFetchableItem({} as Dictionary<string>),
	plan: {
		organizations: getDefaultFetchableItems(),
		isLoaded: false,
		planSets: getDefaultFetchableItems(),
		leaders: getDefaultFetchableItems(),
		focusAreas: getDefaultFetchableItems(),
		functionAreas: getDefaultFetchableItems(),
		progressScales: getDefaultFetchableItems(),
		components: getDefaultFetchableItems(),
		metricStatuses: getDefaultFetchableItems(),
		metricPeriods: getDefaultFetchableItems(),
		focus: {
			isLoaded: false
		},
		showArchived: false
	},
	planEnterprise: {
		goalsTableData: getDefaultFetchableItems(),
		actionStepsTableData: getDefaultFetchableItems(),
		initiativesTableData: getDefaultFetchableItems(),
		linkedGoalsTableData: getDefaultFetchableItems(),
		linkedInitiativesTableData: getDefaultFetchableItems(),
		goalHyperlinksTableData: getDefaultFetchableItems()
	}
};

export interface IEpmState {
	initialized: boolean;
	environment: IFetchableItem<IEnvironment>;
	terms: IFetchableItem<Dictionary<string>>;
	plan: {
		organizations: IFetchableItems<IOrganization>;
		isLoaded: boolean;
		planSets: IFetchableItems<IPlanSet>;
		leaders: IFetchableItems<IUser>;
		focusAreas: IFetchableItems<IIdentifiableWithCaption>;
		functionAreas: IFetchableItems<IIdentifiableWithCaption>;
		progressScales: IFetchableItems<IColoredDataDto>;
		components: IFetchableItems<IIdentifiableWithCaption>;
		metricStatuses: IFetchableItems<IIdentifiableWithCaption>;
		metricPeriods: IFetchableItems<IIdentifiableWithCaption>;
		focus: {
			isLoaded: boolean;
		};
		showArchived: boolean;
	};
	planEnterprise: {
		goalsTableData: IFetchableItems<IGoalDataTableRowDto>;
		actionStepsTableData: IFetchableItems<IActionStepsDataTableRowDto>;
		initiativesTableData: IFetchableItems<IInitiativesDataTableRowDto>;
		linkedGoalsTableData: IFetchableItems<ILinkedItemDataTableRowDto>;
		linkedInitiativesTableData: IFetchableItems<ILinkedItemDataTableRowDto>;
		goalHyperlinksTableData: IFetchableItems<IHyperlinkDataTableRowDto>;
	};
}
