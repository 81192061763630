export default class BroadcastChannelWrapper {
	private readonly channel: BroadcastChannel;
	constructor(name: string) {
		this.channel = new BroadcastChannel(name);
	}

	public set onmessage(handler: (e: MessageEvent) => any) {
		this.channel.onmessage = handler;
	}

	public postMessage(message: string): void {
		this.channel.postMessage(message);
	}
}
