import { IIdentifiableWithCaption } from "@epm/Controls/BaseModels";

export interface IEnvironment {
	userInfo: IUser | null;
	defaultOrganizationId: number | null;
	features: Dictionary<FeatureAccessLevel>;
}

export type IUser = IIdentifiableWithCaption;

export interface IPlanSet {
	planSetId: number;
	name: string;
}

export interface IOrganization extends IIdentifiableWithCaption {
	organizationId: number;
	planId: number;
	name: string;
	gradeColor: string;
	progressColor: string;
	level: number;
	plans: Dictionary<number>;
	leaders: Dictionary<LeaderRoleType>;
}

export enum LeaderRoleType {
	Leader = 1,
	Coordinator = 2
}

export enum EpmFocusTab {
	Overview = "overview",
	Goals = "goals",
	Initiatives = "initiatives",
	ActionSteps = "actionsteps",
	Metrics = "metrics"
}

export enum EpmEnterprisePage {
	Goals = "goals",
	Initiatives = "initiatives",
	ActionSteps = "actionsteps"
}

export enum EpmEnterpriseGoalTab {
	General = "general",
	Initiatives = "initiatives",
	SharedGoals = "sharedgoals",
	Playbooks = "playbooks",
	Notes = "notes",
	Documents = "documents",
	History = "history",
	Links = "links"
}

export enum EpmEnterpriseInitiativeTab {
	General = "general",
	ActionSteps = "actionsteps",
	SharedInitiatives = "sharedinitiatives",
	Playbooks = "playbooks",
	Notes = "notes",
	Documents = "documents",
	History = "history",
	Links = "links"
}

export enum EpmEnterpriseActionStepsTab {
	General = "general",
	Score = "score",
	Notes = "notes",
	Documents = "documents",
	History = "history",
	Links = "links"
}

export enum FetchStatus {
	Empty = "empty",
	Loading = "loading",
	Loaded = "loaded"
}

export interface IFetchableItems<T> {
	items: T[];
	fetchStatus: FetchStatus;
}

export interface IFetchableItem<T> {
	item: T | null;
	fetchStatus: FetchStatus;
}

export interface IFilterWithPaginationDto<T> {
	filter: T;
	take: number;
	skip: number;
	totalLoaded: boolean;
}

export interface IPagingParams {
	skip: number;
	take: number;
}

export class PagingParams implements IPagingParams {
	public readonly skip: number;
	public readonly take: number;
	public readonly totalLoaded: boolean;

	constructor(page: number, take: number, forceLoadTotal: boolean = false) {
		this.take = take;
		this.skip = page * take;
		this.totalLoaded = !forceLoadTotal;
	}
}

export interface IColoredDataDto extends INamedDataDto {
	color: string;
}

export type INamedDataDto = IIdentifiableWithCaption;

export enum AccessLevel {
	Read = 1,
	WriteArtifacts = 2,
	WriteScore = 4,
	WriteChildren = 8,
	WriteDetails = 16
}

export enum FeatureAccessLevel {
	None = 0,
	Read = 1
}

export enum EpmFeature {
	Plans = "plans",
	Metrics = "metrics",
	Performance = "performance"
}

export const EpmFocusTabFeatureMap: Dictionary<EpmFeature> = {
	[EpmFocusTab.Goals]: EpmFeature.Plans,
	[EpmFocusTab.Initiatives]: EpmFeature.Plans,
	[EpmFocusTab.ActionSteps]: EpmFeature.Plans,
	[EpmFocusTab.Metrics]: EpmFeature.Metrics
};

export interface IPlanTopLevelFilterValuesState {
	selectedOrganizations: IOrganization[];
	selectedPlanSet: IPlanSet;
}

export enum HttpStatus {
	OK = 200,
	Forbidden = 403,
	NotFound = 404,
	InternalServerError = 500
}

export interface IErrorResponse {
	data: { status: HttpStatus };
}

export interface IResponseDto {
	status: boolean;
}
