import {
	FavoriteType,
	IMenuItemDto,
	IMenuModel,
	LeftMenuNodeType,
	MenuActionType,
	MenuNodeType,
	ProductType,
	ReportOpenedLocationType,
	UserItemType
} from "@components/Menu/MenuModel";
import { ILeftMenuItem, ILeftMenuLogo, LeftMenuItemType } from "@mede/react-library/components";
import { buildIcon } from "@mede/react-library/utils";
import { FAVORITES_PAGE_CAPTION, WORK_LISTS_PAGE_CAPTION } from "@common/constants";
import { LegacyAppUrls } from "@legacyApp/LegacyAppUrls";
import { buildMenuItemUrl } from "@components/Menu/MenuItemHandler";
import { getIconByUserSettingsPageType } from "@common/menuUtils";
import { v4 as guid } from "uuid";
import {
	faBell,
	faChartMixed,
	faCommentDots,
	faHouse,
	faLightbulb,
	faPeopleGroup,
	faStar,
	faUser,
	faWrench,
	faListUl
} from "@mede/react-library/icons";
import { IconIdentity } from "@mede/react-library/core";

const FAVORITES_PATH = [FAVORITES_PAGE_CAPTION];
Object.freeze(FAVORITES_PATH);

const WORKLISTS_PATH = [WORK_LISTS_PAGE_CAPTION];
Object.freeze(WORKLISTS_PATH);

export function buildProducts(
	menuData: IMenuModel,
	productId: string | undefined,
	selectedItemPath: ISelectedMenuItemInfo[]
): ILeftMenuItem<ILeftMenuItemData>[] {
	const { products, productMenuItems } = menuData.productsData;
	const menuItems = new Array<ILeftMenuItem<ILeftMenuItemData>>();

	for (const product of products) {
		const isCurrentProduct = product.id === productId;
		const item: ILeftMenuItem<ILeftMenuItemData> = {
			type: LeftMenuItemType.Navigation,
			id: "product-" + product.id,
			caption: product.caption,
			icon: product.icon ? buildIcon(product.icon) : faChartMixed
		};

		if (product.type === ProductType.ExternalApp) {
			item.page = "External";
			item.url = product.url;
			item.isNewWindow = product.target?.toLowerCase() === "blank";
		} else {
			item.productId = product.id;
			item.page = "Home";
			item.url = LegacyAppUrls.landingPage;
			item.children =
				productMenuItems[product.id]?.menuItems?.map(x =>
					buildProductMenuItem(x, [], isCurrentProduct ? selectedItemPath : [], 0)
				) ?? [];
		}

		menuItems.push(item);
	}

	return menuItems;
}

function buildProductMenuItem(
	menuItem: IMenuItemDto,
	path: string[],
	selectedItemPath: ISelectedMenuItemInfo[],
	deep: number,
	parentMenuItem?: IMenuItemDto
): ILeftMenuItem<ILeftMenuItemData> {
	let childrenPath = path;
	if (
		menuItem.caption &&
		((menuItem.itemType !== MenuNodeType.Section && menuItem.itemType !== MenuNodeType.Group) ||
			parentMenuItem?.itemType === MenuNodeType.Section)
	) {
		childrenPath = [...path, menuItem.caption];
	}

	const url = buildMenuItemUrl(menuItem.url, menuItem.caption, path, menuItem.itemType, menuItem.key);
	const isActive = selectedItemPath[deep]?.caption == menuItem.caption || selectedItemPath[deep]?.url == url;
	const isSectionItem = menuItem.itemType === MenuNodeType.Section || menuItem.itemType === MenuNodeType.Group;

	return {
		type: isSectionItem ? LeftMenuItemType.Section : LeftMenuItemType.Navigation,
		id: menuItem.id ?? guid(),
		key: menuItem.key,
		isHighlighted: isActive,
		testId: menuItem.testId,
		caption: menuItem.caption ?? undefined,
		productId: menuItem.productId ?? undefined,
		data: {
			itemType: menuItem.itemType
		},
		children:
			menuItem.children?.map(x =>
				buildProductMenuItem(x, childrenPath, selectedItemPath, isSectionItem ? deep : deep + 1, menuItem)
			) ?? [],
		page: menuItem.page ?? undefined,
		url,
		path: path,
		isNewWindow:
			menuItem.actionType === MenuActionType.OpenInPopup || menuItem.actionType === MenuActionType.OpenInTabAllowed
	};
}

const buildFavoritesOrWorkListsProps = (
	menuData: IMenuModel,
	type: Extract<MenuNodeType, "WorkList" | "Favorite">
): {
	hasAccessToShared: boolean;
	personal: IMenuItemDto[];
	organizational: IMenuItemDto[];
	id: string;
	rootId: string;
	caption: string;
	icon: IconIdentity;
} => {
	if (type === MenuNodeType.Favorite) {
		return {
			hasAccessToShared: menuData.settings.hasAccessToReadSharedFavorites,
			personal: menuData.productsData.personalFavoriteItems,
			organizational: menuData.productsData.organizationalFavoriteItems,
			id: "favorites-bucket",
			rootId: "fav-root",
			caption: FAVORITES_PAGE_CAPTION,
			icon: faStar
		};
	}

	return {
		hasAccessToShared: menuData.settings.hasAccessToReadSharedCustomDetails,
		personal: menuData.productsData.personalCustomDetailsItems,
		organizational: menuData.productsData.organizationalCustomDetailsItems,
		id: "workLists-bucket",
		rootId: "workList-root",
		caption: WORK_LISTS_PAGE_CAPTION,
		icon: faListUl
	};
};

export function buildFavoritesOrWorkLists(
	menuData: IMenuModel,
	selectedItem: ISelectedFavoriteInfo | null,
	type: Extract<MenuNodeType, "WorkList" | "Favorite">
): ILeftMenuItem<ILeftMenuItemData> | null {
	const { hasAccessToShared, personal, organizational, id, rootId, caption, icon } = buildFavoritesOrWorkListsProps(
		menuData,
		type
	);

	if (
		!menuData.settings.hasAnyProductConfigured ||
		menuData.productsData.products.length === 0 ||
		(type === MenuNodeType.WorkList && !(personal?.length || organizational?.length))
	) {
		return null;
	}

	const buckets = [
		buildFavoriteOrWorkListBucket(
			personal,
			FavoriteType.Personal,
			selectedItem?.isPersonal ? selectedItem.caption : null,
			type
		)
	];

	if (hasAccessToShared) {
		buckets.push(
			buildFavoriteOrWorkListBucket(
				organizational,
				FavoriteType.Shared,
				selectedItem != null && !selectedItem.isPersonal ? selectedItem.caption : null,
				type
			)
		);
	}

	return {
		id,
		type: LeftMenuItemType.Navigation,
		caption,
		icon,
		children: [
			{
				type: LeftMenuItemType.Section,
				caption,
				children: buckets
			},
			createViewAllFavoritesOrWorkListsItem(rootId, FavoriteType.Personal, type)
		]
	};
}

function buildFavoriteOrWorkListBucket(
	sections: IMenuItemDto[],
	favoriteType: FavoriteType,
	selectedCaption: string | null,
	type: Extract<MenuNodeType, "WorkList" | "Favorite">
): ILeftMenuItem<ILeftMenuItemData> {
	const prefixId = type === MenuNodeType.Favorite ? "fav-" : "workList-";

	const reportLocationPersonal =
		type === MenuNodeType.Favorite
			? ReportOpenedLocationType.PersonalFavorites
			: ReportOpenedLocationType.PersonalWorkLists;
	const reportLocationOrganizational =
		type === MenuNodeType.Favorite
			? ReportOpenedLocationType.OrganizationalFavorites
			: ReportOpenedLocationType.OrganizationalWorkLists;

	const locationType = favoriteType === FavoriteType.Personal ? reportLocationPersonal : reportLocationOrganizational;

	const id = prefixId + (favoriteType === FavoriteType.Personal ? "personal" : "organization");

	return {
		type: LeftMenuItemType.Navigation,
		id,
		caption: favoriteType === FavoriteType.Personal ? "Personal" : "Organizational",
		isHighlighted: selectedCaption != null,
		children: [
			...sections.map(x => buildFavoriteOrWokrListSection(x, locationType, selectedCaption, type)),
			createViewAllFavoritesOrWorkListsItem(id, favoriteType, type)
		]
	};
}

function createViewAllFavoritesOrWorkListsItem(
	id: string,
	favoriteType: FavoriteType,
	type: Extract<MenuNodeType, "WorkList" | "Favorite">
) {
	const bucketCaption = type === MenuNodeType.Favorite ? FAVORITES_PAGE_CAPTION : WORK_LISTS_PAGE_CAPTION;
	const url = (type === MenuNodeType.Favorite ? LegacyAppUrls.favorites : LegacyAppUrls.workLists) + favoriteType;

	return {
		id: "view-manage-" + id,
		type: LeftMenuItemType.Navigation,
		bucketCaption,
		caption: "View All & Manage...",
		url
	};
}

function buildFavoriteOrWokrListSection(
	section: IMenuItemDto,
	locationType: ReportOpenedLocationType,
	selectedCaption: string | null,
	type: Extract<MenuNodeType, "WorkList" | "Favorite">
): ILeftMenuItem<ILeftMenuItemData> {
	const mostUsedText = type === MenuNodeType.Favorite ? "Top 5 Visited Favorites" : "Top 5 Visited Work Lists";
	const recentlyUsedText =
		type === MenuNodeType.Favorite ? "5 Most Recently Used Favorites" : "5 Most Recently Used Work Lists";

	const caption = section.leftMenuNodeType === LeftMenuNodeType.MostUsedFolder ? mostUsedText : recentlyUsedText;

	const path = type === MenuNodeType.Favorite ? FAVORITES_PATH : WORKLISTS_PATH;

	return {
		type: LeftMenuItemType.Section,
		caption,
		children:
			section.children?.slice(0, 5).map(x => {
				return {
					id: x.key,
					type: LeftMenuItemType.Navigation,
					caption: x.caption!,
					url: buildMenuItemUrl(x.url, x.caption, path, x.itemType),
					page: x.page ?? undefined,
					path,
					isHighlighted: x.caption == selectedCaption,
					data: {
						itemType: x.itemType,
						openedLocationType: locationType
					}
				};
			}) ?? []
	};
}

export function buildInsights(menuData: IMenuModel): ILeftMenuItem<ILeftMenuItemData> | null {
	if (!menuData.settings.hasInsightsAccess) {
		return null;
	}

	return {
		type: LeftMenuItemType.Navigation,
		id: "insights-page",
		caption: "Insights Panel",
		icon: faLightbulb,
		page: "insights",
		url: reactPublicUrl + "/insights"
	};
}

export function buildCohorts(menuData: IMenuModel): ILeftMenuItem<ILeftMenuItemData> | null {
	if (!menuData.settings.hasCohortsAccess) {
		return null;
	}

	return {
		type: LeftMenuItemType.Navigation,
		id: "view-cohorts-page",
		caption: "Cohorts",
		icon: faPeopleGroup,
		page: "cohorts",
		url: reactPublicUrl + "/cohorts"
	};
}

export function buildAlerts(notifications: IMenuItemDto[], count: number): ILeftMenuItem<ILeftMenuItemData> | null {
	const alerts = notifications.find(x => x.page === "Alerts");
	if (alerts == null) {
		return null;
	}

	return {
		type: LeftMenuItemType.Navigation,
		caption: alerts.caption!,
		icon: faBell,
		id: "alerts-page",
		url: buildMenuItemUrl(alerts.url, alerts.caption),
		page: alerts.page!,
		badge: getBadgeText(count)
	};
}

export function buildCollaborations(
	notifications: IMenuItemDto[],
	count: number
): ILeftMenuItem<ILeftMenuItemData> | null {
	const collaborations = notifications.find(x => x.page === "Collaborations");
	if (collaborations == null) {
		return null;
	}

	return {
		type: LeftMenuItemType.Navigation,
		caption: "Collaborations",
		icon: faCommentDots,
		id: "collaborations-page",
		url: buildMenuItemUrl(collaborations.url, "Collaborations"),
		page: collaborations.page!,
		badge: getBadgeText(count)
	};
}

function getBadgeText(count: number): string | undefined {
	if (count === 0) {
		return undefined;
	}

	return count > 9 ? "9+" : count.toString();
}

export function buildTools(
	tools: IMenuItemDto[],
	selectedItemPath: ISelectedMenuItemInfo[]
): ILeftMenuItem<ILeftMenuItemData> | null {
	const menuItems = new Array<ILeftMenuItem<ILeftMenuItemData>>();

	for (const tool of tools) {
		menuItems.push(buildToolMenuItem(tool, selectedItemPath, 0));
	}

	if (menuItems.length > 0) {
		return {
			type: LeftMenuItemType.Navigation,
			caption: "Tools",
			icon: faWrench,
			id: "tools-bucket",
			children: menuItems
		};
	}

	return null;
}

function buildToolMenuItem(
	menuItem: IMenuItemDto,
	selectedItemPath: ISelectedMenuItemInfo[],
	deep: number
): ILeftMenuItem<ILeftMenuItemData> {
	const url = buildMenuItemUrl(menuItem.url, menuItem.caption);
	const isSectionItem = menuItem.itemType === MenuNodeType.Section || menuItem.itemType === MenuNodeType.Group;
	const isActive = selectedItemPath[deep]?.caption == menuItem.caption || selectedItemPath[deep]?.url == url;

	return {
		type: isSectionItem ? LeftMenuItemType.Section : LeftMenuItemType.Navigation,
		caption: menuItem.caption ?? undefined,
		id: menuItem.id ?? guid(),
		testId: menuItem.testId,
		page: menuItem.page ?? undefined,
		isHighlighted: isActive,
		url,
		isNewWindow:
			menuItem.actionType === MenuActionType.OpenInPopup || menuItem.actionType === MenuActionType.OpenInTabAllowed,
		children:
			menuItem.children?.map(item => buildToolMenuItem(item, selectedItemPath, isSectionItem ? deep : deep + 1)) ?? []
	};
}

export function buildLogo(menu: IMenuModel): ILeftMenuLogo {
	return {
		largeLogo: buildUrl(menu.largeLogo?.imageSrc),
		smallLogo: buildUrl(menu.smallLogo?.imageSrc)
	};
}

function buildUrl(url: string): string | null {
	if (url == null || url.length == 0 || url.startsWith("http") || url.startsWith("/")) {
		return url;
	}

	return "/" + url;
}

export function buildUserMenuItems(items: Dictionary<IMenuItemDto[]>): ILeftMenuItem<ILeftMenuItemData> {
	return {
		type: LeftMenuItemType.Navigation,
		id: "tool-user-settings",
		caption: "Account & Settings",
		icon: faUser,
		children: buildChildUserMenuItems(items)
	};
}

function buildChildUserMenuItems(userItems: Dictionary<IMenuItemDto[]>): ILeftMenuItem<ILeftMenuItemData>[] {
	const menuItems = new Array<ILeftMenuItem<ILeftMenuItemData>>();
	for (const key in userItems) {
		if (key === UserItemType.Logout) {
			continue;
		}

		userItems[key].forEach((item: IMenuItemDto) => {
			menuItems.push({
				id: item.id,
				icon: getIconByUserSettingsPageType(item.page),
				caption: item.caption ?? undefined,
				type: LeftMenuItemType.Navigation,
				testId: item.testId,
				data: {
					itemType: item.itemType
				},
				url: buildMenuItemUrl(item.url, item.caption),
				isNewWindow:
					item.actionType === MenuActionType.OpenInPopup || item.actionType === MenuActionType.OpenInTabAllowed
			});
		});
	}

	if (userItems[UserItemType.Logout] != null) {
		const logoutItem = userItems[UserItemType.Logout][0];
		if (logoutItem != null) {
			menuItems.push({ type: LeftMenuItemType.Divider });
			menuItems.push({
				type: LeftMenuItemType.Navigation,
				caption: UserItemType.Logout,
				icon: getIconByUserSettingsPageType(UserItemType.Logout),
				page: UserItemType.Logout,
				testId: logoutItem.testId
			});
		}
	}
	return menuItems;
}

export function buildHomeButton(productId: string | undefined): ILeftMenuItem<ILeftMenuItemData> {
	return {
		type: LeftMenuItemType.Navigation,
		icon: faHouse,
		caption: "Home",
		id: `product-${productId ?? "id"}-home`,
		productId: productId,
		page: "Home",
		url: LegacyAppUrls.landingPage
	};
}

export interface ILeftMenuItemData {
	openedLocationType?: ReportOpenedLocationType;
	itemType?: MenuNodeType | keyof typeof MenuNodeType;
}

export interface ISelectedMenuItemInfo {
	caption: string;
	url: string | null;
}

export interface ISelectedFavoriteInfo {
	isPersonal: boolean;
	caption: string;
}

export type ISelectedWorkListInfo = ISelectedFavoriteInfo;
