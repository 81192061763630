import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import initialState from "@redux/initialState";
import { DialogEntity, DialogType, IDialogSettings } from "@components/Dialog/DialogEntity";
import { AppDispatch, RootState } from "@redux/store";
import { decreaseSystemAlerts } from "@components/App/appSlice";
import { LegacyAppUrls } from "@legacyApp/LegacyAppUrls";
import { v4 as guid } from "uuid";

const dialogSlice = createSlice({
	name: "dialog",
	initialState: initialState.dialog,
	reducers: {
		openDialog: {
			reducer(
				state,
				action: PayloadAction<{ dialogType: DialogType; settings: IDialogSettings; defaultReturnModel: any }>
			) {
				const { dialogType, settings, defaultReturnModel } = action.payload;
				const lastId = state.dialogs.length > 0 ? state.dialogs[state.dialogs.length - 1].id : 0;

				const dialog = new DialogEntity(lastId + 1, dialogType, settings, defaultReturnModel);
				state.dialogs.push({ ...dialog });
			},
			prepare(dialogType: DialogType, settings: IDialogSettings, defaultReturnModel?: any) {
				return { payload: { dialogType, settings, defaultReturnModel } };
			}
		},
		closeDialog(state, action: PayloadAction<number>) {
			const dialogIndex = state.dialogs.findIndex(x => x.id === action.payload);
			state.dialogs[dialogIndex] = { ...state.dialogs[dialogIndex], isOpen: false };
		},
		removeDialog(state, action: PayloadAction<number>) {
			const dialogIndex = state.dialogs.findIndex(x => x.id === action.payload);
			if (dialogIndex >= 0) {
				state.dialogs.splice(dialogIndex, 1);
			}
		},
		updateDialog: {
			reducer(state, action: PayloadAction<{ id: number; settings: Partial<IDialogSettings> }>) {
				const { id, settings } = action.payload;
				const dialogIndex = state.dialogs.findIndex(x => x.id === id);
				if (dialogIndex >= 0) {
					state.dialogs[dialogIndex].settings = { ...state.dialogs[dialogIndex].settings, ...settings };
				}
			},
			prepare(id: number, settings: Partial<IDialogSettings>) {
				return { payload: { id, settings } };
			}
		},
		setDialogReturnModel: {
			reducer(state, action: PayloadAction<{ id: number; data: any }>) {
				const { id, data } = action.payload;
				const dialog = state.dialogs.find(x => x.id === id);
				if (dialog != null) {
					dialog.getReturnModel = () => data;
				}
			},
			prepare(id: number, data: any) {
				return { payload: { id, data } };
			}
		},
		triggerDialogResize(state, action: PayloadAction<number>) {
			const dialogIndex = state.dialogs.findIndex(x => x.id === action.payload);
			if (dialogIndex >= 0) {
				state.dialogs[dialogIndex].dialogResizeTrigger = guid();
			}
		}
	}
});

export const { openDialog, closeDialog, removeDialog, updateDialog, setDialogReturnModel, triggerDialogResize } =
	dialogSlice.actions;

export default dialogSlice.reducer;

export function showSystemAlerts() {
	return async (dispatch: AppDispatch, getState: () => RootState) => {
		const systemAlerts = getState().app.configuration.systemAlertIds;
		if (systemAlerts.length === 0) {
			return;
		}

		const alertId = systemAlerts[0];
		const closeDialogHandler = (): void => {
			dispatch(decreaseSystemAlerts());
			dispatch(showSystemAlerts());
		};
		const dialogSettings = getDialogSettings(alertId, closeDialogHandler);
		dispatch(openDialog(DialogType.Frame, dialogSettings));
	};
}

function getDialogSettings(alertId: number, closeDialogHandler: () => void): IDialogSettings {
	return {
		url: `${LegacyAppUrls.getSystemAlertById}${encodeURIComponent(alertId)}`,
		height: 625,
		width: 700,
		isHideByEscKey: false,
		closeOnBackdrop: false,
		closeDialogCallback: closeDialogHandler
	} as IDialogSettings;
}
