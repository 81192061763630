import "./AppFooter.scss";
import { MedeIcon, MedeButton } from "@mede/react-library/components";
import { faCopyright, faRectangleTerminal } from "@mede/react-library/icons";
import { useEffect, useState } from "react";
import { pageInfoApi } from "@components/AppFooter/pageInfoApi";
import { IPageInfo } from "@components/AppFooter/PageModels";
import { Dialog, DialogBody, DialogFooter } from "@blueprintjs/core";
import { useAppSelector } from "@redux/reduxHooks";

export default function AppFooter() {
	const [model, setModel] = useState<IPageInfo | null>(null);
	const [dialogOpened, setDialogOpened] = useState(false);
	const isReady = useAppSelector(s => s.app.configuration.hasAccess);
	const toggleDialog = () => setDialogOpened(!dialogOpened);

	useEffect(() => {
		if (!isReady) {
			return;
		}

		pageInfoApi.getInfo().then(data => setModel(data));
	}, [isReady]);

	if (model == null) {
		return (
			<div className={"app-footer"}>
				<div className={"app-footer-content"}>
					<div className={"app-footer-copyright"}>
						<span className={"copyright"} data-testid={"copyright"}>
							<MedeIcon icon={faCopyright} /> MedeAnalytics {new Date().getUTCFullYear()}
						</span>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={"app-footer"}>
			<div className={"app-footer-content"}>
				{model.customFooterText && <div className={"app-footer-text"}>{model.customFooterText}</div>}
				<div className={"app-footer-copyright"}>
					<span className={"copyright"} data-testid={"copyright"}>
						<MedeIcon icon={faCopyright} /> MedeAnalytics {new Date().getUTCFullYear()}
					</span>
					<span className={"build-version"}>Build Version {model.buildVersion}</span>
					{model.customizedFooterLinks.length > 0 ? (
						model.customizedFooterLinks.map(link => (
							<a key={link.caption} className={"link"} href={link.url} target="_blank" rel="noreferrer">
								{link.caption}
							</a>
						))
					) : (
						<>
							<a className={"terms"} href={model.termsOfUseUrl} target="_blank" rel="noreferrer">
								TERMS OF USE
							</a>
							{!model.hidePrivacyPolicy && model.privacyPolicyUrl && (
								<a className={"policy"} href={model.privacyPolicyUrl} target="_blank" rel="noreferrer">
									PRIVACY POLICY
								</a>
							)}
						</>
					)}
					{model.showClientFooterLogo && (
						<img
							id={"footer_logo"}
							src={model.footerLogoUrl || "/clients/images/mede_logo_large.png"}
							alt={model.footerLogoAlt}
						/>
					)}
					{model.showViewSource && model.infoModel && (
						<>
							<MedeButton
								dataTestId={"view-source-info"}
								className="view-source-info"
								aria-label="View Source Info"
								onClick={toggleDialog}
								minimal
								icon={faRectangleTerminal}
								small
							/>
							<SourceInfoDialog model={model} toggle={toggleDialog} isOpen={dialogOpened} />
						</>
					)}
				</div>
			</div>
		</div>
	);
}

function SourceInfoDialog(props: Readonly<{ toggle: () => void; isOpen: boolean; model: IPageInfo }>) {
	const { totalDeliveryTime, processingTime } = useAppSelector(s => s.tracker);
	const sourceInfo = props.model.infoModel;

	return (
		<Dialog onClose={props.toggle} isOpen={props.isOpen} title={"Source Info"} className={"source-dialog"} lazy={true}>
			<DialogBody>
				<div data-testid={"source-info"}>
					<SourceInfoLine caption="Organization Unit" value={sourceInfo.organizationUnit} />
					<SourceInfoLine caption="Web Server Name" value={sourceInfo.webServerName} />
					<SourceInfoLine caption="Environment Path" value={sourceInfo.environmentPath} />
					<SourceInfoLine caption="Config Path" value={sourceInfo.configPath} />
					<SourceInfoLine caption="Admin Db Server" value={sourceInfo.adminDbHost} />
					<SourceInfoLine caption="Admin Db Name" value={sourceInfo.adminDbName} />
					<SourceInfoLine caption="Admin DB Type" value={sourceInfo.adminDbType} />
					<SourceInfoLine caption="Processing time" value={processingTime?.toFixed(2)} />
					<SourceInfoLine caption="Total delivery time" value={totalDeliveryTime?.toFixed(2)} />
					<SourceInfoLine
						caption="Build Version"
						value={props.model.buildVersion}
						href={`https://bitbucket.medeanalytics.com/projects/PLATFORM/repos/mede/commits?until=${sourceInfo.buildHash}`}
					/>
					<SourceInfoLine caption="Build Date" value={sourceInfo.buildDate} />
				</div>
			</DialogBody>
			<DialogFooter actions={<MedeButton outlined text="Close" onClick={props.toggle} />} />
		</Dialog>
	);
}

function SourceInfoLine({
	caption,
	value,
	href
}: Readonly<{ caption: string; value: string; href?: string }>): JSX.Element | null {
	if (!value) {
		return null;
	}

	return (
		<div className={"section"}>
			<span className={"caption"}>{caption}: </span>
			{href ? (
				<a className={"value"} href={href} target={"_blank"} rel="noreferrer">
					{value}
				</a>
			) : (
				<span className={"value"}>{value}</span>
			)}
		</div>
	);
}
