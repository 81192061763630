import { MenuItemPageType } from "@components/Menu/MenuModel";
import { IconIdentity } from "@mede/react-library/core";
import { faAlignJustify, faGlobe, faLock, faPowerOff, faWrench } from "@mede/react-library/icons";

export function getIconByUserSettingsPageType(page?: string | null): IconIdentity | undefined {
	if (page == null || page.length === 0) {
		return;
	}

	const pageType = page as MenuItemPageType;
	switch (pageType) {
		case MenuItemPageType.UserPreferences:
			return faWrench;
		case MenuItemPageType.Profile:
			return faGlobe;
		case MenuItemPageType.ChangePassword:
			return faLock;
		case MenuItemPageType.ToolbarUserLayoutManagement:
			return faAlignJustify;
		case MenuItemPageType.Logout:
			return faPowerOff;
	}
}
