import { IMedeConfirmationDialogProps } from "@mede/react-library/components";
import { createContext, ReactNode, useCallback, useContext, useMemo, useRef, useState } from "react";

export type IPromptDialogProps = Omit<IMedeConfirmationDialogProps, "isOpen">;

export interface IPromptDialog {
	id: number;
	props: IPromptDialogProps;
}

interface IPromptsContext {
	dialogs: IPromptDialog[];
	showDialog: (props: IPromptDialogProps) => number;
	removeDialog: (id: number) => void;
}

const PromptDialogsContext = createContext<IPromptsContext | undefined>(undefined);

export function PromptDialogsProvider({ children }: { children: ReactNode }) {
	const [dialogs, setDialogs] = useState<IPromptDialog[]>([]);
	const lastId = useRef<number>(0);

	const removeDialog = useCallback((id: number) => {
		setDialogs(prev => prev.filter(x => x.id != id));
	}, []);

	const showDialog = useCallback((props: IPromptDialogProps) => {
		const dialog = {
			id: ++lastId.current,
			props
		};
		setDialogs(prev => [...prev, dialog]);
		return dialog.id;
	}, []);

	const value = useMemo(() => {
		return {
			dialogs,
			showDialog,
			removeDialog
		};
	}, [dialogs, showDialog, removeDialog]);

	return <PromptDialogsContext.Provider value={value}>{children}</PromptDialogsContext.Provider>;
}

export const usePromptDialogs = () => {
	const context = useContext(PromptDialogsContext);
	if (!context) {
		throw new Error("usePrompts must be used within an PromptDialogsProvider");
	}

	return context;
};

export const useShowPrompt = () => {
	const context = useContext(PromptDialogsContext);
	if (!context) {
		throw new Error("useShowPrompt must be used within an PromptDialogsProvider");
	}

	return context.showDialog;
};
