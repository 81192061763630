import { IMenuItemDto, MenuActionType, MenuNodeType } from "./MenuModel";
import { IPageData } from "@common/common-types";
import { ILeftMenuItem } from "@mede/react-library/components";
import { ILeftMenuItemData } from "@components/Menu/LeftMenu/LeftMenuBuilder";

export function parseMenuItem(item: IMenuItemDto, path?: string[]): IPageData | null {
	const page = handlePageAction(item.page ?? null, item.url ?? undefined);
	if (page) {
		return page;
	}

	if (!item.url) {
		return null;
	}

	const url = buildMenuItemUrl(item.url, item.caption, path, item.itemType, item.key);
	return { page: "External", url, isNewWindow: isNewPage(item) };
}

export function parseLeftMenuItem(item: ILeftMenuItem<ILeftMenuItemData>): IPageData | null {
	const page = handlePageAction(item.page ?? null, item.url);
	if (page) {
		return page;
	}

	if (!item.url) {
		return null;
	}

	const url = buildMenuItemUrl(item.url, item.caption ?? null, item.path, item.data?.itemType, item.key);
	return { page: "External", url, isNewWindow: item.isNewWindow };
}

export function buildMenuItemUrl(
	url: string | null,
	caption: string | null,
	path?: string[],
	itemType?: MenuNodeType | keyof typeof MenuNodeType,
	key?: string | null
): string {
	if (url == null || url.length === 0) {
		return "";
	}

	if (itemType === MenuNodeType.UrlNode && key && key === ["url", url].join("^$^")) {
		return url;
	}

	const uri = url.startsWith("http") ? new URL(url) : new URL(url, window.location.origin);
	if (itemType != null && isReportMenuItem(itemType)) {
		if (caption != null) {
			uri.searchParams.set("selectedMenuItemCaption", caption);
		}

		if (path != null && path.length > 0) {
			uri.searchParams.set("selectedMenuBucketCaption", path[0]);
		}
	}

	if (uri.hostname != window.location.hostname) {
		return uri.href;
	}

	return getRelativeMenuItemUrl(uri);
}

function isReportMenuItem(itemType: MenuNodeType | keyof typeof MenuNodeType): boolean {
	return (
		itemType === MenuNodeType.SuperQuery ||
		itemType === MenuNodeType.Report ||
		itemType === MenuNodeType.ReportViewer ||
		itemType === MenuNodeType.SsrsIntegrated ||
		itemType === MenuNodeType.Board ||
		itemType === MenuNodeType.Calculator ||
		itemType === MenuNodeType.Rstate ||
		itemType === MenuNodeType.FavBoard ||
		itemType === MenuNodeType.Detailed
	);
}

function getRelativeMenuItemUrl(uri: URL): string {
	let url = uri.href.replace(uri.origin, "");
	if (!url.startsWith("/")) {
		url = "/" + url;
	}

	return url;
}

function isNewPage(data: IMenuItemDto): boolean {
	if (!data.url) {
		return false;
	}

	return (
		data.actionType === MenuActionType.OpenInPopup ||
		data.actionType === MenuActionType.OpenInTabAllowed ||
		data.url.includes("_blank")
	);
}

function handlePageAction(page: string | null, url?: string): IPageData | null {
	switch (page?.toLowerCase()) {
		case "usergroups":
		case "addcollaboration":
		case "home":
		case "logout":
		case "refreshtopmenu":
		case "insights":
		case "cohorts":
			return { page: page, url: url };
		case "collaborations":
		case "alerts":
		case "board":
		case "summaryreport":
		case "epm":
			return {
				page: url != null && !url.includes("/app/") ? "External" : page,
				url
			};
	}

	return null;
}
