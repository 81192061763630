import { getJson, postText } from "@mede/react-library/utils";
import { IShareUser } from "./common-types";

const setAsViewedUrl = "/platform-api/notification/set-viewed-events";

export function setEventsAsViewed(ids: number[]): Promise<string> {
	if (ids.length === 0) {
		return Promise.resolve("");
	}

	return postText(setAsViewedUrl, ids, { suppressError: true });
}

export function setEventsAsViewedBeacon(ids: number[]) {
	if (ids.length === 0) {
		return;
	}

	const headers = {
		type: "application/json",
		"X-Csrf-Token": window.xcsrfHeaderValue
	};

	const data = new Blob([JSON.stringify(ids)], headers);

	navigator.sendBeacon(setAsViewedUrl, data);
}

export function getShareUsers(): Promise<IShareUser[]> {
	return getJson<IShareUser[]>("platform-api/account/share-users");
}
