import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "@redux/reduxHooks";
import { logOut, setExpire, setAboutToExpire, sessionIsAboutToExpire } from "@components/App/appSlice";
import { CookieManager, saveDataInSession } from "@mede/react-library/utils";
import {
	SESSION_EXPIRATION_COOKIE_NAME,
	UPDATE_SESSION_ABOUT_TO_EXPIRE_STATE,
	UPDATE_SESSION_STATE_METHOD,
	XCSRF_TOKEN_KEY
} from "@common/constants";
import HubProxy from "@common/HubProxy";
import SessionBroadcastChannel from "@components/App/SessionExpiration/SessionBroadcastChannel";
import { SessionStatus } from "@components/App/SessionExpiration/SessionExpirationTypes";

const SESSION_EXPIRATION_SERVICE_URL = "/SessionExpirationChecker.asmx";
const PLATFORM_SESSION_KEY = "SESSION_";
let lastUnExpire: number | null = null;

export default function useSessionExpiration() {
	const isLegacy = useAppSelector(s => s.app.isLegacyAppMode);
	const isExpired = useAppSelector(s => s.app.isExpired);
	const isReady = useAppSelector(s => s.app.configuration.hasAccess);
	const isLoggedIn = useAppSelector(s => s.app.isLoggedIn);
	const dispatch = useAppDispatch();
	const broadcastChannel = SessionBroadcastChannel();
	const hubProxy = HubProxy();
	const isReadyListenExpiration = useRef(false);

	useEffect(() => {
		window.login = setSessionLoggedIn;
		window.logoutClick = logoutClickHandler;

		broadcastChannel.subscribeOnLoggedIn(data => setSessionLoggedIn(data.token));
		broadcastChannel.subscribeOnTimeOut(setSessionTimeout);
		broadcastChannel.subscribeOnLoggedOut(onSessionLogout);
		broadcastChannel.subscribeOnLoggedOutByOtherSession(onSessionLogoutBySessionChange);
		broadcastChannel.subscribeOnAny(cleanSession);

		const updateStateHandler = (state: SessionStatus) => {
			if (state !== SessionStatus.TimeOut || !isUnexpiredRecently()) {
				setSessionState(state);
			}
		};

		const updateAboutToExpireState = () => {
			setSessionIsAboutToExpire();
		};

		hubProxy.subscribe(UPDATE_SESSION_STATE_METHOD, updateStateHandler);
		hubProxy.subscribe(UPDATE_SESSION_ABOUT_TO_EXPIRE_STATE, updateAboutToExpireState);

		return () => {
			hubProxy.unsubscribe(UPDATE_SESSION_STATE_METHOD, updateStateHandler);
			hubProxy.unsubscribe(UPDATE_SESSION_ABOUT_TO_EXPIRE_STATE, updateAboutToExpireState);
		};
	}, []);

	useEffect(() => {
		// Skip first useEffect call that is caused by mount, not property change
		if (!isReadyListenExpiration.current) {
			isReadyListenExpiration.current = true;
			return;
		}

		if (isExpired) {
			broadcastChannel.notifyTimeOut();
			document.body.classList.add("session-expired");
		} else {
			lastUnExpire = new Date().getTime();
			hubProxy.reconnect();
			CookieManager.setCookie(SESSION_EXPIRATION_COOKIE_NAME, SessionStatus.LoggedIn);
			broadcastChannel.notifyLoggedIn(window.xcsrfHeaderValue);

			document.body.classList.remove("session-expired");
		}
	}, [isExpired]);

	useEffect(() => {
		if (!isLoggedIn) {
			broadcastChannel.notifyLoggedOut();
		}
	}, [isLoggedIn]);

	useEffect(() => {
		if (!isReady) {
			return;
		}

		const cookieState = CookieManager.getCookie(SESSION_EXPIRATION_COOKIE_NAME) as SessionStatus;
		if (cookieState != null) {
			setSessionState(cookieState);
		}

		if (cookieState == null || cookieState === SessionStatus.LoggedIn) {
			broadcastChannel.notifyLoggedIn(window.xcsrfHeaderValue);
		}
	}, [isReady]);

	useEffect(() => {
		if (!isLegacy) {
			return;
		}

		const isSessionActive = () => !isExpired;
		window.sessionExpirationManager = wrapLegacySessionExpirationManager(isSessionActive);
	}, [isLegacy, isExpired]);

	/* All Functions must be pure. I.e. not use current state, only set it, otherwise use useCallback */
	function setSessionState(state: SessionStatus) {
		CookieManager.setCookie(SESSION_EXPIRATION_COOKIE_NAME, state);
		switch (state) {
			case SessionStatus.LoggedIn:
				setSessionLoggedIn();
				break;
			case SessionStatus.TimeOut:
				cleanSession();
				setSessionTimeout();
				break;
			case SessionStatus.LoggedOut:
				onSessionLogout();
				break;
			case SessionStatus.LoggedOutByOtherSession:
				broadcastChannel.notifyLoggedOutByOtherSession();
				onSessionLogoutBySessionChange();
				break;
		}
	}

	function setSessionLoggedIn(token?: string) {
		setToken(token);
		dispatch(setExpire(false));
	}

	function setSessionTimeout() {
		dispatch(setAboutToExpire(false));
		dispatch(setExpire(true));
	}

	function setSessionIsAboutToExpire() {
		dispatch(sessionIsAboutToExpire());
	}

	function onSessionLogout() {
		processLogout(false);
	}

	function onSessionLogoutBySessionChange() {
		processLogout(true);
	}

	function processLogout(isByOtherSession: boolean) {
		setTimeout(() => {
			dispatch(logOut(isByOtherSession));
		}, 100);
	}

	function logoutClickHandler(_?: unknown, logoutMessage?: string): void {
		if (logoutMessage && logoutMessage.length > 0) {
			window.document.cookie = `logoutMessage=${logoutMessage};secure;path=/`;
		}

		onSessionLogout();
	}
}

function wrapLegacySessionExpirationManager(isSessionActive: () => boolean): LegacySessionExpirationManager {
	return {
		isSessionActive: isSessionActive,
		isSessionExpirationUrl: (url: string) => url.toLowerCase().includes(SESSION_EXPIRATION_SERVICE_URL.toLowerCase())
	};
}

function setToken(token: string | undefined) {
	if (token == null || token.length === 0) {
		return;
	}

	setTokenOnWindow(token, window);
	saveDataInSession(XCSRF_TOKEN_KEY, token);

	const frames = document.getElementsByTagName("iframe");
	for (const frame of frames) {
		setTokenOnWindow(token, frame.contentWindow);
	}
}

function setTokenOnWindow(token: string, win: Window | null) {
	if (win == null) {
		return;
	}

	win.xcsrfHeaderValue = token;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const jquery = (win as any).$;
	if (jquery?.ajaxSetup != null) {
		jquery.ajaxSetup({
			headers: {
				"X-Csrf-Token": token
			}
		});
	}
}

function cleanSession() {
	for (const key in window.localStorage) {
		if (key.startsWith(PLATFORM_SESSION_KEY)) {
			window.localStorage.removeItem(key);
		}
	}
}

function isUnexpiredRecently() {
	if (lastUnExpire == null) {
		return false;
	}

	const now = new Date().getTime();
	return (now - lastUnExpire) / 1000 < 40;
}

export function resetLastUnExpire() {
	lastUnExpire = null;
}
