export enum SessionStatus {
	LoggedIn = "LoggedIn",
	TimeOut = "TimeOut",
	LoggedOut = "LoggedOut",
	LoggedOutByOtherSession = "LoggedOutByOtherSession"
}

export interface ISessionExpirationConfig {
	incorrectPasswordMessage: string;
	ssoReloginUrl: string;
	isSsoLogin: boolean;
	showSsoLogout: boolean;
	clientSessionExpirationLogo: string;
	showReloginPageInPopup: boolean;
	unAuthorizedIpMessage: string;
	isLoggedInWithIdentityServer: boolean;
	identityReloginUrl: string;
}

export enum AuthenticationState {
	AuthFailed = "AuthFailed",
	UnAuthorizedIPaddress = "UnAuthorizedIPaddress",
	Logout = "Logout",
	Login = "Login"
}
