import { getJson } from "@mede/react-library/utils";
import { LegacyAppUrls } from "./LegacyAppUrls";

export namespace legacyApplicationApi {
	export function getPendoOptions() {
		return getJson(LegacyAppUrls.getPendoOptions);
	}

	export function getExternalUrls() {
		return getJson(LegacyAppUrls.getExternalUrls);
	}
}
