import { SignalRHubProxy } from "@mede/react-library/utils";

let hubProxyInstance: SignalRHubProxy | null = null;
function getInstance(): SignalRHubProxy {
	if (hubProxyInstance == null) {
		hubProxyInstance = new SignalRHubProxy("/platform-api/hub");
		hubProxyInstance.init();
	}

	return hubProxyInstance;
}

export default getInstance;
