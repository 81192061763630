import { MedeAnchorButton, MedeErrorPage } from "@mede/react-library/components";
import { faHeadset } from "@mede/react-library/icons";

export const SERVER_ERROR_TITLE = "500";
export const SERVER_ERROR_SUBTITLE = "Server Error";
export const SERVER_ERROR_DESCRIPTION = "Try to refresh this page or feel free to contact us if the problem persist";

export function ErrorContent() {
	return (
		<MedeErrorPage title={SERVER_ERROR_TITLE} subtitle={SERVER_ERROR_SUBTITLE} description={SERVER_ERROR_DESCRIPTION}>
			<MedeAnchorButton
				large
				outlined
				text="Contact Support"
				icon={faHeadset}
				href="https://medeanalytics.com/support/"
				target="_blank"
			/>
			<MedeAnchorButton large intent={"primary"} text="Back to Homepage" href="/LandingPage" />
		</MedeErrorPage>
	);
}
