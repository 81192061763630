import { ReactNode } from "react";
import { BlueprintProvider } from "@blueprintjs/core";
import { SessionDataPipelineProvider } from "@common/context/SessionDataPipelineContext";
import { PromptDialogsProvider } from "@components/Dialog/PromptProvider";

export default function AppProvider({ children }: { children: ReactNode }) {
	return (
		<BlueprintProvider>
			<SessionDataPipelineProvider>
				<PromptDialogsProvider>{children}</PromptDialogsProvider>
			</SessionDataPipelineProvider>
		</BlueprintProvider>
	);
}
