import { EpmFocusTab } from "@epm/epmModel";

export class EpmRouter {
	public static readonly TAB_ID_PLACEHOLDER = ":tabId";

	public static readonly ROOT = "achieve";
	public static readonly FOCUS = "focus";
	public static readonly FOCUS$TAB_ID = `${EpmRouter.FOCUS}/${EpmRouter.TAB_ID_PLACEHOLDER}`;

	static getFocus() {
		return `/${EpmRouter.ROOT}/${EpmRouter.FOCUS}`;
	}

	public static getFocusTab(tab: EpmFocusTab): string {
		return `/${EpmRouter.ROOT}/${EpmRouter.FOCUS$TAB_ID.replace(EpmRouter.TAB_ID_PLACEHOLDER, tab)}`;
	}

	public static getRoute(route: string): string {
		return `/${EpmRouter.ROOT}/${route}`;
	}
}
