import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { IPostDateModel } from "./PostDateModel";

const { actions, reducer } = createSlice({
	name: "postDate",
	initialState: {
		info: "",
		additionalInfo: null
	} as IPostDateModel,
	reducers: {
		setPostDate: (state, { payload }: PayloadAction<IPostDateModel>) => {
			if (state.info === payload.info && state.additionalInfo === payload.additionalInfo) {
				return;
			}

			state.info = payload.info;
			state.additionalInfo = payload.additionalInfo;
		}
	}
});

export const { setPostDate } = actions;

export default reducer;
