import { IMenuItemDto, IProductMenuModel, MenuNodeType } from "./MenuModel";

enum PageType {
	summary,
	detail,
	board,
	ssrs
}

export class SearchMenuService {
	public findPageInMenu(
		products: Dictionary<IProductMenuModel>,
		pageUrl: string | null,
		productId: string | undefined
	): IMenuItemAndPath | null {
		const searchParams = this.getPageParams(pageUrl);
		if (searchParams == null) {
			return null;
		}

		const menuItems = this.findInProducts(products, searchParams);
		if (productId == null) {
			return menuItems[0];
		}

		return menuItems.find(x => x.menuItem.productId === productId) ?? menuItems[0];
	}

	private getPageParams(uri: string | null): IPageParams | null {
		if (!uri) {
			return null;
		}

		const url = new URL(uri, window.location.origin);
		const boardId = this.getBoardIdFromUrl(url);
		if (boardId) {
			return {
				boardId,
				type: PageType.board
			};
		}

		const ssrsId = this.getSsrsIdFromUrl(url);
		if (ssrsId != null) {
			return {
				ssrsId,
				type: PageType.ssrs
			};
		}

		const { reportId, stateId, type } = this.getSummaryOrDetail(url);
		if (reportId && stateId) {
			return {
				reportId,
				stateId,
				type
			};
		}

		return null;
	}

	private getBoardIdFromUrl(url: URL): string | null {
		const path = url.pathname.toLowerCase();
		if (path.includes("/app/board/")) {
			return path.substring(path.indexOf("/board/") + 7).split("/")[0];
		}

		if (!path.includes("/boards/")) {
			return null;
		}

		const boardId = url.searchParams.get("boardId");
		if (boardId != null) {
			return boardId;
		}

		const lastSegment = path.substring(path.indexOf("boards/") + 7);
		if (lastSegment.length > 0 && !lastSegment.includes("/")) {
			return lastSegment;
		}

		return null;
	}

	private getSsrsIdFromUrl(url: URL): string | null {
		if (!url.pathname.includes("/SsrsIntegrated/")) {
			return null;
		}

		return url.searchParams.get("ssrsId");
	}

	private getSummaryOrDetail(url: URL): IPageParams {
		const path = url.pathname.toLowerCase();
		if (path.includes("app/summaryreport/")) {
			const [reportId, stateId] = path.substring(path.indexOf("/summaryreport/") + 9).split("/");
			return {
				reportId,
				stateId,
				type: PageType.summary
			};
		}

		const reportId = url.searchParams.get("reportId") ?? undefined;
		const stateId = url.searchParams.get("stateId") ?? undefined;
		const type = url.pathname.includes("/Summary4G.mvc/") ? PageType.summary : PageType.detail;
		return {
			reportId,
			stateId,
			type
		};
	}

	private findInProducts(products: Dictionary<IProductMenuModel>, searchParams: IPageParams): IMenuItemAndPath[] {
		let foundItems: IMenuItemAndPath[] = [];
		for (const productsKey in products) {
			const menuItems = this.findInMenuItems(products[productsKey].menuItems, searchParams);
			if (menuItems.length > 0) {
				foundItems = [...foundItems, ...menuItems];
			}
		}

		return foundItems;
	}

	private findInMenuItems(
		menuItems: IMenuItemDto[],
		searchParams: IPageParams,
		path: string[] = [],
		parentMenuItem: IMenuItemDto | null = null
	): IMenuItemAndPath[] {
		let foundItems: IMenuItemAndPath[] = [];
		for (const menuItem of menuItems) {
			if (menuItem.children?.length) {
				const childrenPath =
					menuItem.itemType === MenuNodeType.Folder ||
					(menuItem.itemType === MenuNodeType.Section && parentMenuItem?.itemType === MenuNodeType.Section)
						? [...path, menuItem.caption!]
						: path;
				const childrenItems = this.findInMenuItems(menuItem.children, searchParams, childrenPath, menuItem);
				if (childrenItems.length > 0) {
					foundItems = [...foundItems, ...childrenItems];
				}
			} else {
				const pageParams = this.getPageParams(menuItem.url);
				if (this.paramsMatch(pageParams, searchParams)) {
					foundItems.push({ path, menuItem });
				}
			}
		}

		return foundItems;
	}

	private paramsMatch(a: IPageParams | null, b: IPageParams | null): boolean {
		if (a == null || b == null || a.type !== b.type) {
			return false;
		}

		switch (a.type) {
			case PageType.board:
				return a.boardId === b.boardId;
			case PageType.ssrs:
				return a.ssrsId === b.ssrsId;
			case PageType.summary:
			case PageType.detail:
				return a.reportId === b.reportId && a.stateId === b.stateId;
			default:
				return false;
		}
	}
}

interface IPageParams {
	reportId?: string;
	stateId?: string;
	boardId?: string;
	ssrsId?: string;
	type: PageType;
}

export interface IMenuItemAndPath {
	path: string[];
	menuItem: IMenuItemDto;
}
