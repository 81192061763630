import { getJson, postJson } from "@mede/react-library/utils";
import { IFavoritesMenuData, IMenuModel, ISwitchProductDto } from "./MenuModel";
import { ISearchResultProductModel } from "./TopNavigation/TopNavigationModel";

export namespace menuApi {
	export function getMenu(updateCache: boolean) {
		return postJson<IMenuModel>(
			"platform-api/menu/get",
			{ updateCache },
			{ requestInit: { priority: "high" }, suppressError: true }
		);
	}

	export function getFavorites(updateCache: boolean) {
		return postJson<IFavoritesMenuData>(
			"platform-api/menu/favorites",
			{ updateCache },
			{ requestInit: { priority: "high" }, suppressError: true }
		);
	}

	export function searchMenuItems(searchStr: string) {
		return getJson<ISearchResultProductModel[]>("platform-api/menu/search", { searchStr });
	}

	export function smartSearchMenuItems(searchStr: string) {
		return getJson<ISearchResultProductModel[]>("platform-api/menu/smart-search", { searchStr });
	}

	export function switchProduct(productId: string, suppressError = false): Promise<ISwitchProductDto> {
		return postJson("platform-api/menu/switch-product", { productId }, { suppressError });
	}
}
